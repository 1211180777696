@import url("https://fonts.googleapis.com/css2?family=Alata&family=Jost:wght@100&family=Madimi+One&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.visible-options {
  display: none; /* Initially don't display the element */
  animation: slideInFromLeft 0.5s ease-in forwards; /* Use the animation */
}

.hidden {
  display: none;
}

.visible-options {
  display: block; /* Or 'flex', etc., based on your layout needs */
  animation: slideInFromLeft 0.5s ease-in forwards;
}



.selected {
  border-color: black;
  /* or any highlight color you prefer */
  background-color: #f0f0f0;
  /* Light background or any color to indicate selection */
}

.color-name-popup {

  font-weight: 700;
  padding-top: 50px;

}

.color-option {
  transition: transform 0.2s ease-in-out, border 0.2s ease;
  cursor: pointer;
}

.color-option:hover {
  transform: translateY(-10px);
}


.continue-button {
  margin-bottom: 20px;
  background-color: #001b74;
  color: white;
  border-radius: 20px;
  font-size: 0.8rem;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
  width: 200px;
  height: 50px;

  /* Removed margin-top since it's no longer needed */
}


/********************************************************************************************************* Small devices (landscape phones, 576px and up) */
@media only screen and (max-width: 500px) {

  .option-title h2 {
    font-weight: bold;

    margin-bottom: 15px;
    font-size: 18px;
  }


  .payment-options-mobile-max {
    display: flex;
    flex-wrap: nowrap;
    /* This prevents wrapping */
    justify-content: center;
    /* This will center the items in the container */
  }

  .payment-option {
    background-color: #efefef;
    border-radius: 10px;
    margin: 10px;
    /* Adjust spacing between options */
    width: calc(33.333% - 20px);
    /* Adjusts the width to fit three items in a row minus margin */
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Optional: adds some depth */
  }

  .model-selection {
    border: 1px solid #ccc;
    /* Light border color */
    border-radius: 20px;
    /* Large radius for rounded corners */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1rem;
    /* Space below the model selection box */
  }

  .payment-option h3 {
    margin-top: 0;
    font-size: 12px;
  }

  .payment-option p {
    margin-top: 0;
    font-size: 12px;
  }

  .payment-price {
    font-weight: bold;
    margin-bottom: 5px;
  }


  .mobile-selection-container {
    flex-direction: column;
    align-items: center;
    padding-top: 0px;
    display: flex;
    max-width: 100vw;
    /* Dark blue background */
    margin-top: 5%;
    position: relative;

  }

  .new-tag {

    text-align: center;
  }

  .price {

    text-align: center;
  }

  .mobile-selection-container-title {

    font-size: 22px;
    text-align: center;
  }


  .left-section,
  .right-section-mobile {
    width: 100%;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
  }

  .image-container-mobile,
  .choose-kosher-level,
  .what-in-box {
    width: 100%;
  }

  .monthly-data-plan-mobile {
    text-align: center;
    /* Center aligns the text for all children */
    padding: 20px;
    /* Adds some space inside the container */
  }

  .monthly-data-plan-mobile h2 {
    padding-bottom: 12px;
    font-size: 18px;
  }

  .data-plan-options-mobile {
    display: flex;
    /* Use flexbox for horizontal layout */
    justify-content: center;
    /* Centers the flex items on the main axis */
    gap: 15px;
    /* Adds space between each data plan option */
    padding-bottom: 10px;
    /* Adds some space at the bottom inside the container */
    height: 100px;
  }

  .data-plan-option-mobile {
    min-width: 70px;
    /* Minimum width for each option */
    background-color: #f7f7f7;
    /* Light grey background for each option */
    border-radius: 10px;
    /* Rounded corners for each option */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* Subtle shadow for depth */
    display: flex;
    /* Use flexbox for layout */
    flex-direction: column;
    /* Stack the amount and price vertically */
    align-items: center;
    /* Center-align items horizontally within the flex container */
    height: 60px;
    padding-top: 5px;

  }

  .data-amount {
    font-size: 0.8rem;
    /* Larger font size for the data amount */
    font-weight: bold;
    /* Makes the amount bold */
    color: #333;
    /* Dark grey or black color for text */
    margin-bottom: 5px;
    /* Space between amount and price */
  }

  .data-price {
    font-size: 0.7rem;
    /* Slightly smaller font size for price */
    color: #666;
    /* Lighter grey for price */
  }


  .image-container-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    /* For absolute positioning of arrows */
  }

  .arrow-left-mobile {
    left: -70px;
    /* Adjust as necessary */
    font-weight: 900;
    background-color: #ffffff00;
    /* Light grey background */
    border: none;
    cursor: pointer;
    outline: none;
    width: 40px;
    font-size: 24px;
    /* Large arrow font */
    position: absolute;
    /* Position arrows absolutely within the container */
    margin-left: 30%;
    margin-right: 30%;
  }

  .arrow-right-mobile {
    right: -70px;
    /* Adjust as necessary */
    font-weight: 900;
    background-color: #ffffff00;
    /* Light grey background */
    border: none;
    cursor: pointer;
    outline: none;
    width: 40px;
    font-size: 24px;
    /* Large arrow font */
    position: absolute;
    /* Position arrows absolutely within the container */
    margin-left: 30%;
    margin-right: 30%;
  }

  .phone-image-mobile {

    width: 150px;
    height: 250px;

  }

  .phone-image-container {
    width: 250px;
    /* Adjust to the width of your image */
    height: 400px;
    /* Adjust to the height of your image */
    display: flex;
    /* Use flexbox to center the image */
    justify-content: center;
    align-items: center;
    background: #ffffff00;
    /* White background to match your image */
    border-radius: 20px;
    /* Rounded corners if needed */
  }

  .what-in-box-mobile {
    justify-content: center;
    /* This centers the items inside the container */
    align-items: center;
    background: #f1f1f1;
    border-radius: 20px;
    margin: 0 auto 30px auto;
    /* This should center the container itself */
    width: fit-content;
    /* This will make the width as wide as its content */
    padding: 10px;
    /* Add some padding if needed */
    display: flex;
  }


  .what-in-box-mobile .item {
    text-align: center;
    width: 80px;
  }

  .what-in-box-mobile .item img {
    width: 50px;
    /* Adjust as needed */
    margin-bottom: 10px;
  }

  .what-in-box-mobile .item p {
    font-size: 1rem;
    color: #333;
    /* Other styles to match your UI */
  }


  .in-box-text-mobile {
    margin-top: 20px;
    padding: 20px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #00244b;
  }

  .color-selection-mobile-page {
    display: flex;
    justify-content: center;
    /* Centers child elements within the container */
    align-items: center;
    /* Vertically aligns child elements */
    margin-left: auto;
    /* Centers the container itself */
    margin-right: auto;
    /* Centers the container itself */
    margin-bottom: 20px;
    /* Remove specific left margin to allow auto margins to work */
  }

  .choose-color-text {
    text-align: center;
    font-size: 18px;
  }

  .color-option {
    width: 40px;
    /* Size of the color circle */
    height: 40px;
    /* Size of the color circle */
    border-radius: 50%;
    /* Make it round */
    margin: 0 5px;
    /* Space between circles */
    cursor: pointer;
    /* Indicates you can click on them */
    border: 2px solid #ddd;
    /* Light border for color circles */
    margin-top: 15px;
  }

  .model-title-mobile {
    font-size: 15px;
    text-align: center;
    margin-left: 10px;
    padding-bottom: 10px;
  }

  .model-info-mobile {
    margin-left: 25px;
    margin-right: 25px;
  }

  .port-number-section-mobile {
    width: 100%;
  }


  .port-number-section-mobile h2 {
    margin-right: 10px;
    /* Space for the info icon */
    font-family: 'Nunito', sans-serif;
    font-size: 1rem;
    /* Adjust the font size as needed */
    margin-bottom: 15px;
    text-align: center;
  }

  .choose-kosher-level-mobile {
    margin: auto;
    text-align: center;
    align-items: center;
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #f0f0f0;
    -webkit-overflow-scrolling: touch;
    padding: 10px;
    flex-wrap: wrap;
    justify-content: flex-start; /* Aligns items to the start of the container */
  }
  
  .choose-kosher-level-mobile .kosher-level-button {
    position: relative; /* Needed for absolute positioning of the tooltip */
    margin: 10px;
    padding: 10px;
    background: #f3f3f3;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 15px;
    color: #000000;
    height: 100px;
    width: 100px;
    box-sizing: border-box;
  }
  

  .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: rgb(0, 38, 91);
    color: #ffffff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position the tooltip above the button */
    left: 50%;
    margin-left: -60px; /* Use half of the width (set above) to center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }
  .kosher-level-button:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  /* Custom scrollbar for Webkit browsers */
  .choose-kosher-level-mobile::-webkit-scrollbar {
    width: 5px;
    /* Adjust scrollbar width */
  }

  .choose-kosher-level-mobile::-webkit-scrollbar-track {
    background: #f0f0f0;
    /* Track color */
  }

  .choose-kosher-level-mobile::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    /* Thumb color */
    border-radius: 4px;
    /* Thumb radius */
  }

  .choose-kosher-level-mobile::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
    /* Thumb hover color */
  }


  .choose-kosher-level-mobile h2 {
    margin-bottom: 15px;
    /* Adjust as needed */
    text-align: center;
    margin-top: 10%;
    margin-left: 25px;
  }





  /* Extra styles to handle hover and active states */
  .choose-kosher-level-mobile button:hover,
  .choose-kosher-level-mobile button:focus {
    background: #868686;
    /* Slightly darker grey on hover/focus */
  }

  .choose-kosher-level-mobile button:active {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.61);
  }

  .option-title {
    text-align: center;
    font-weight: bold;

  }

  .port-button-mobile {
    background-color: #001b74;
    /* Adjust the color to match your theme */
    color: white;
    border: none;
    padding: 10px 50px;
    border-radius: 20px;
    /* Rounded corners for the button */
    font-size: 1rem;
    /* Adjust the font size as needed */
    cursor: pointer;
    /* Changes the cursor to indicate button is clickable */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* Optional: adds depth */
    border: 1px solid transparent;
    /* Helps retain button shape when clicked */
    align-self: center;
    /* Centers button within flex container */
    display: block;
    /* Centers button in the normal flow */
    margin-left: auto;
    margin-right: auto;
    /* Auto margins for horizontal centering */
  }

  .port-button-mobile:hover {
    background-color: #001b74;
    /* Adjust the color to match your theme */
  }

}







/***************************************************************************************************************** Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 500px) and (max-width: 950px) {
  .mobile-selection-container {
    display: flex;
    width: 100%;
    padding-top: 20%;
    position: relative;

  }

  /***************left side****************/
  .left-section {
    width: 65%;
    text-align: left;
    padding-left: 5%;
  }

  /* DeviceSelectionPage.css */
  .new-tag {
    font-family: "Nunito", sans-serif;
    color: #009abd;
    font-weight: 600;
    margin-bottom: 0;
    /* Remove bottom margin if it exists */
    font-size: 1rem;
    /* Adjust the font size as needed */
    padding-bottom: 30px;
    /* other styles... */
  }

  .mobile-selection-container-title {
    font-family: "Nunito", sans-serif;
    font-size: 2rem;
    /* Adjust the font size as needed */
    line-height: 0;
    /* Decrease the line-height */
    margin-bottom: 0;
    /* Remove bottom margin if it exists */
    text-align: left;
  }

  .price {
    font-family: "Nunito", sans-serif;
    font-size: 1.2rem;
    /* Adjust the font size as needed */
    margin-top: 5%;
    /* Remove top margin if it exists */
  }

  .product-image {
    background-color: #eeeded;
    /* Replace with your image */
    width: 100%;
    /* Adjust based on your layout */
    height: 500px;
    /* Adjust based on your layout */
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .payment-options-mobile-max {
    display: flex;
    /* Use flexbox to layout children in a row */
    flex-wrap: wrap;
    /* Allows items to wrap onto the next line on smaller screens */
    margin-bottom: 20px;
    font-family: "Nunito", sans-serif;
    justify-content: space-between;
    /* Distributes space around items */


  }

  .payment-option {
    background-color: #efefef;
    /* For visibility */
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    /* Space between options */
    width: 25%;
    cursor: pointer;

  }

  .payment-option h3 {
    margin-top: 0;
  }

  .payment-price {
    font-weight: bold;
    margin-bottom: 5px;
  }


  .option-title h2 {
    font-family: 'Nunito', sans-serif;
    font-size: 1.25rem;
    /* Adjust the font size as needed */
    font-weight: bold;
    /* Nunito is available in various weights, choose as needed */
    color: #333;
    /* Adjust the color as needed */
    margin-bottom: 16px;
    /* Adjust the space below the header as needed */
  }

  .phone-image-mobile {

    width: 180px;
    height: 300px;

  }

  /* Add to your existing styles */

  .monthly-data-plan {
    margin-top: 20px;
    /* Space from the payment options section */
    margin-bottom: 10px;
  }

  .monthly-data-plan h2 {
    margin-bottom: 15px;
  }

  .data-plan-options-mobile {
    display: flex;
    justify-content: space-between;
    width: 600px;
    gap: 50px;
    /* Creates a gap between the plan options */
  }


  .data-plan-option-mobile {
    background-color: #efefef;
    /* For visibility */
    border-radius: 10px;
    /* Rounded corners for the card */
    padding: 20px;
    text-align: center;
    /* Center the text within the card */
    width: calc(25% - 20px);
    /* Adjust the width as necessary */
    /* The calc function adjusts the width accounting for the gap */

  }

  .data-amount {
    display: block;
    /* Ensures it takes its own line */
    font-size: 1.5rem;
    /* Adjust the font size as necessary */
    font-weight: bold;
    margin-bottom: 0.5rem;
    /* Space between the data amount and the price */
  }

  .data-price {
    display: block;
    /* Ensures it takes its own line */
    font-size: 1.2rem;
    /* Adjust the font size as necessary */
    color: #333;
    /* Adjust the color as necessary */
  }


  /* DeviceSelectionPage.css */

  /* ... other styles ... */

  .port-number-section-mobile {
    width: 100%;
    /* Full width of its container */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Align children to the start (left) */
    margin-top: 3%;
  }


  .port-number-section-mobile h2 {
    display: inline-block;
    /* Make the h2 inline for the icon to sit next to it */
    margin-right: 10px;
    /* Space for the info icon */
    font-family: 'Nunito', sans-serif;
    font-size: 1.5rem;
    /* Adjust the font size as needed */
    margin-bottom: 15px;

  }

  .port-button-mobile {
    background-color: #001b74;
    /* Adjust the color to match your theme */
    color: white;
    border: none;
    padding: 10px 50px;
    border-radius: 20px;
    /* Rounded corners for the button */
    font-size: 1rem;
    /* Adjust the font size as needed */
    cursor: pointer;
    /* Changes the cursor to indicate button is clickable */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* Optional: adds depth */
    border: 1px solid transparent;
    /* Helps retain button shape when clicked */
    align-self: flex-start;
    /* Align button to the start (left) if it's a flex child */

  }

  .port-button-mobile:hover {
    background-color: #001b74;
    /* Adjust the color to match your theme */
  }




  /***************right side****************/
  .right-section-mobile {

    margin-top: 20%;
    margin-left: 5%;
    margin-right: 5%;

  }



  .model-title-mobile {
    font-size: 1rem;
    /* Adjust as needed */
    margin-bottom: 0.5rem;
    /* Spacing between title and selection */
  }

  .info-icon {
    /* Style for the info icon */
    font-size: 1rem;
    /* Match title font size */
    cursor: pointer;
  }

  .model-selection {
    border: 1px solid #ccc;
    /* Light border color */
    border-radius: 20px;
    /* Large radius for rounded corners */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1rem;
    /* Space below the model selection box */
  }

  .model-details {
    text-align: left;
  }

  .model-name {
    font-weight: bold;
    font-size: 1.2rem;
    /* Larger font size for the model name */
  }

  .model-size {
    font-size: 1rem;
    /* Smaller font size for the display size */
  }

  .model-price {
    font-size: 1rem;
    text-align: right;
  }





  .choose-color-text {

    margin-top: 10%;
  }

  .color-option {
    width: 40px;
    /* Size of the color circle */
    height: 40px;
    /* Size of the color circle */
    border-radius: 50%;
    /* Make it round */
    margin: 0 5px;
    /* Space between circles */
    cursor: pointer;
    /* Indicates you can click on them */
    border: 2px solid #ddd;
    /* Light border for color circles */
    margin-top: 10%;


  }

  .color-option:hover {
    border-color: #aaa;
    /* Darker border on hover */
  }

  /* You can also add media queries for responsive design if necessary */


  /* WhatsInTheBox.css */
  .what-in-box-mobile {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #f1f1f1;
    padding: 20px;
    border-radius: 20px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    margin: 1%;
    /* Other styles to match your UI */
  }

  .what-in-box-mobile .item {
    text-align: center;
  }

  .what-in-box-mobile .item img {
    width: 100px;
    /* Adjust as needed */
    margin-bottom: 10px;
  }

  .what-in-box-mobile .item p {
    font-size: 1rem;
    color: #333;
    /* Other styles to match your UI */
  }

  .in-box-text-mobile {


    padding: 20px;
    font-size: 3em;
    font-weight: bold;
    color: #00244b;


  }
 .choose-kosher-level-mobile {
    margin: auto;
    text-align: center;
    align-items: center;
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #f0f0f0;
    -webkit-overflow-scrolling: touch;
    padding: 10px;
    flex-wrap: wrap;
    justify-content: flex-start; /* Aligns items to the start of the container */
  }
  
  .choose-kosher-level-mobile .kosher-level-button {
    position: relative; /* Needed for absolute positioning of the tooltip */
    margin: 10px;
    padding: 10px;
    background: #f3f3f3;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 15px;
    color: #000000;
    height: 100px;
    width: 100px;
    box-sizing: border-box;
  }
  

  .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: rgb(0, 38, 91);
    color: #ffffff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position the tooltip above the button */
    left: 50%;
    margin-left: -60px; /* Use half of the width (set above) to center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }
  .kosher-level-button:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  /* Custom scrollbar for Webkit browsers */
  .choose-kosher-level-mobile::-webkit-scrollbar {
    width: 8px;
    /* Adjust scrollbar width */
  }

  .choose-kosher-level-mobile::-webkit-scrollbar-track {
    background: #f0f0f0;
    /* Track color */
  }

  .choose-kosher-level-mobile::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    /* Thumb color */
    border-radius: 4px;
    /* Thumb radius */
  }

  .choose-kosher-level-mobile::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
    /* Thumb hover color */
  }


  .choose-kosher-level-mobile h2 {
    margin-bottom: 20px;
    /* Adjust as needed */
    text-align: left;
    margin-top: 10%;
  }



 

  /* Extra styles to handle hover and active states */
  .choose-kosher-level-mobile button:hover,
  .choose-kosher-level-mobile button:focus {
    background: #868686;
    /* Slightly darker grey on hover/focus */
  }

  .choose-kosher-level-mobile button:active {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.61);
  }


  .color-selection-mobile-page {
    display: flex;
    margin-bottom: 20px;
  }


  .image-container-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    /* For absolute positioning of arrows */
  }

  .arrow-left-mobile {
    left: -70px;
    /* Adjust as necessary */
    font-weight: 900;
    background-color: #ffffff00;
    /* Light grey background */
    border: none;
    cursor: pointer;
    outline: none;
    width: 40px;
    font-size: 24px;
    /* Large arrow font */
    position: absolute;
    /* Position arrows absolutely within the container */
    margin-left: 20%;
    margin-right: 20%;
  }

  .arrow-right-mobile {
    right: -70px;
    /* Adjust as necessary */
    font-weight: 900;
    background-color: #ffffff00;
    /* Light grey background */
    border: none;
    cursor: pointer;
    outline: none;
    width: 40px;
    font-size: 24px;
    /* Large arrow font */
    position: absolute;
    /* Position arrows absolutely within the container */
    margin-left: 20%;
    margin-right: 20%;
  }

  .phone-image-container {
    width: 300px;
    /* Adjust to the width of your image */
    height: 500px;
    /* Adjust to the height of your image */
    display: flex;
    /* Use flexbox to center the image */
    justify-content: center;
    align-items: center;
    background: #ffffff00;
    /* White background to match your image */
    border-radius: 20px;
    /* Rounded corners if needed */

  }

  .phone-image {
    max-width: 100%;
    /* Ensure image is contained within the container */
    max-height: 100%;
    /* Ensure image is contained within the container */
  }



  /* YourComponent.css */
  .image-container {
    text-align: center;
    position: relative;
  }

  .image-container img {
    max-width: 100%;
    height: auto;
  }

  .color-buttons {
    margin-top: 20px;
  }

  .color-buttons button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    margin-right: 5px;
  }

  .image-container>button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .image-container>button:first-child {
    left: 10px;
  }

  .image-container>button:last-child {
    right: 10px;
  }





}









/****************************************************************************************************************** Large devices (desktops, 992px and up) */
@media only screen and (min-width: 951px) {

  /***************left side****************/
  .mobile-selection-container {
    display: flex;
    width: 100%;
    position: relative;
    padding-top: 5%;

  }

  .left-section {
    width: 65%;
    text-align: left;
    padding-left: 5%;
  }


  /* DeviceSelectionPage.css */

  .new-tag {
    font-family: "Nunito", sans-serif;
    color: #009abd;
    font-weight: 600;
    margin-bottom: 0;
    /* Remove bottom margin if it exists */
    font-size: 1rem;
    /* Adjust the font size as needed */
    padding-bottom: 30px;

    /* other styles... */
  }

  .mobile-selection-container-title {
    font-family: "Nunito", sans-serif;
    font-size: 2rem;
    /* Adjust the font size as needed */
    line-height: 0;
    /* Decrease the line-height */
    margin-bottom: 0;
    /* Remove bottom margin if it exists */
    text-align: left;
  }

  .price {
    font-family: "Nunito", sans-serif;
    font-size: 1.2rem;
    /* Adjust the font size as needed */
    margin-top: 5%;
    /* Remove top margin if it exists */
  }

  .product-image {
    background-color: #eeeded;
    /* Replace with your image */
    width: 100%;
    /* Adjust based on your layout */
    height: 500px;
    /* Adjust based on your layout */
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .payment-options-mobile-max {
    display: flex;
    /* Use flexbox to layout children in a row */
    /* Distributes space around items */
    flex-wrap: wrap;
    gap: 30px;
    /* Allows items to wrap onto the next line on smaller screens */
    margin-bottom: 20px;
    font-family: "Nunito", sans-serif;

  }

  .payment-option {
    background: #f8f8f8;
    /* For visibility */
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    /* Space between options */
    width: 25%;
    cursor: pointer;


  }

  .payment-option h3 {
    margin-top: 0;
  }

  .payment-price {
    font-weight: bold;
    margin-bottom: 5px;
  }


  .option-title h2 {
    font-family: 'Nunito', sans-serif;
    font-size: 1.25rem;
    /* Adjust the font size as needed */
    font-weight: bold;
    /* Nunito is available in various weights, choose as needed */
    color: #333;
    /* Adjust the color as needed */
    margin-bottom: 16px;
    /* Adjust the space below the header as needed */
  }

  /* Add to your existing styles */

  .monthly-data-plan {
    margin-top: 20px;
    /* Space from the payment options section */
    margin-bottom: 10px;
  }

  .monthly-data-plan h2 {
    margin-bottom: 15px;
  }

  .data-plan-options-mobile {
    display: flex;
    justify-content: space-between;
    width: 600px;
    gap: 50px;
    /* Creates a gap between the plan options */
    margin-top: 20px;
  }


  .data-plan-option-mobile {
    background: #f8f8f8;
    /* For visibility */
    border-radius: 10px;
    /* Rounded corners for the card */
    padding: 20px;
    text-align: center;
    /* Center the text within the card */
    width: calc(25% - 20px);
    /* Adjust the width as necessary */
    cursor: pointer;

  }

  .data-amount {
    display: block;
    /* Ensures it takes its own line */
    font-size: 1.5rem;
    /* Adjust the font size as necessary */
    font-weight: bold;
    margin-bottom: 0.5rem;
    /* Space between the data amount and the price */
  }

  .data-price {
    display: block;
    /* Ensures it takes its own line */
    font-size: 1.2rem;
    /* Adjust the font size as necessary */
    color: #333;
    /* Adjust the color as necessary */
  }


  /* DeviceSelectionPage.css */

  /* ... other styles ... */

  .port-number-section-mobile {
    width: 100%;
    /* Full width of its container */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Align children to the start (left) */
    margin-top: 3%;
  }


  .port-number-section-mobile h2 {
    display: inline-block;
    /* Make the h2 inline for the icon to sit next to it */
    margin-right: 10px;
    /* Space for the info icon */
    font-family: 'Nunito', sans-serif;
    font-size: 1.5rem;
    /* Adjust the font size as needed */
    margin-bottom: 15px;

  }

  .port-button-mobile {
    background-color: #001b74;
    /* Adjust the color to match your theme */
    color: white;
    border: none;
    padding: 10px 50px;
    border-radius: 20px;
    /* Rounded corners for the button */
    font-size: 1rem;
    /* Adjust the font size as needed */
    cursor: pointer;
    /* Changes the cursor to indicate button is clickable */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* Optional: adds depth */
    border: 1px solid transparent;
    /* Helps retain button shape when clicked */
    align-self: flex-start;
    /* Align button to the start (left) if it's a flex child */

  }

  .port-button-mobile:hover {
    background-color: #001b74;
    /* Adjust the color to match your theme */
  }




  /***************right side****************/
  .right-section-mobile {
    width: 35%;
    text-align: left;
    padding-left: 20px;
    margin-top: 10%;
    padding-left: 5%;
    padding-right: 5%;
  }



  .model-title-mobile {
    font-size: 1rem;
    /* Adjust as needed */
    margin-bottom: 0.5rem;
    /* Spacing between title and selection */
  }

  .info-icon {
    /* Style for the info icon */
    font-size: 1rem;
    /* Match title font size */
    cursor: pointer;
  }

  .model-selection {
    border: 1px solid #ccc;
    /* Light border color */
    border-radius: 20px;
    /* Large radius for rounded corners */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1rem;
    /* Space below the model selection box */
  }

  .model-details {
    text-align: left;
  }

  .model-name {
    font-weight: bold;
    font-size: 1.2rem;
    /* Larger font size for the model name */
  }

  .model-size {
    font-size: 1rem;
    /* Smaller font size for the display size */
  }

  .model-price {
    font-size: 1rem;
    text-align: right;
  }





  .choose-color-text {

    margin-top: 10%;
  }

  .color-option {
    width: 40px;
    /* Size of the color circle */
    height: 40px;
    /* Size of the color circle */
    border-radius: 50%;
    /* Make it round */
    margin: 0 5px;
    /* Space between circles */
    cursor: pointer;
    /* Indicates you can click on them */
    border: 2px solid #ddd;
    /* Light border for color circles */
    margin-top: 20px;

  }

  .color-option:hover {
    border-color: #aaa;
    /* Darker border on hover */
  }

  /* You can also add media queries for responsive design if necessary */


  /* WhatsInTheBox.css */
  .what-in-box-mobile {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #f8f8f8;
    padding: 20px;
    border-radius: 20px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    margin: 1%;
    /* Other styles to match your UI */
  }

  .what-in-box-mobile .item {
    text-align: center;
  }

  .what-in-box-mobile .item img {
    width: 100px;
    /* Adjust as needed */
    margin-bottom: 10px;
  }

  .what-in-box-mobile .item p {
    font-size: 1rem;
    color: #333;
    /* Other styles to match your UI */
  }

  .in-box-text-mobile {


    padding: 20px;
    font-size: 3em;
    font-weight: bold;
    color: #00244b;


  }

  .choose-kosher-level-mobile {
    margin: auto;
    text-align: center;
    align-items: center;
    scrollbar-width: thin;
    scrollbar-color: #d2d2d2 #f0f0f0;
    -webkit-overflow-scrolling: touch;
    padding: 10px;
    flex-wrap: wrap;
    justify-content: flex-start; /* Aligns items to the start of the container */
  }
  
  .choose-kosher-level-mobile .kosher-level-button {
    position: relative; /* Needed for absolute positioning of the tooltip */
    margin: 10px;
    padding: 10px;
    background: #f3f3f3;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 15px;
    color: #000000;
    height: 100px;
    width: 100px;
    box-sizing: border-box;
  }
  

  .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: rgb(0, 38, 91);
    color: #ffffff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position the tooltip above the button */
    left: 50%;
    margin-left: -60px; /* Use half of the width (set above) to center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .kosher-level-button:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  


  /* Custom scrollbar for Webkit browsers */
  .choose-kosher-level-mobile::-webkit-scrollbar {
    width: 8px;
    /* Adjust scrollbar width */
  }

  .choose-kosher-level-mobile::-webkit-scrollbar-track {
    background: #f0f0f0;
    /* Track color */
  }

  .choose-kosher-level-mobile::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    /* Thumb color */
    border-radius: 4px;
    /* Thumb radius */
  }

  .choose-kosher-level-mobile::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
    /* Thumb hover color */
  }


  .choose-kosher-level-mobile h2 {
    margin-bottom: 20px;
    /* Adjust as needed */
    text-align: left;
    margin-top: 10%;
  }



  /* Extra styles to handle hover and active states */
  .choose-kosher-level-mobile button:hover,
  .choose-kosher-level-mobile button:focus {
    background: #868686;
    /* Slightly darker grey on hover/focus */
  }

  .choose-kosher-level-mobile button:active {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.61);
  }


  .color-selection-mobile-page {
    display: flex;
    margin-bottom: 20px;
  }


  .image-container-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    /* For absolute positioning of arrows */
  }

  .arrow {
    background-color: #ffffff00;
    /* Light grey background */
    border: none;
    cursor: pointer;
    outline: none;
    width: 40px;
    font-size: 24px;
    /* Large arrow font */
    position: absolute;
    /* Position arrows absolutely within the container */
    margin-left: 10%;
    margin-right: 10%;

  }

  .arrow-left-mobile {
    left: -70px;
    /* Adjust as necessary */
    font-weight: 900;
    background-color: #ffffff00;
    /* Light grey background */
    border: none;
    cursor: pointer;
    outline: none;
    width: 40px;
    font-size: 35px;
    /* Large arrow font */
    position: absolute;
    /* Position arrows absolutely within the container */
    margin-left: 10%;
    margin-right: 10%;
  }

  .arrow-right-mobile {
    right: -70px;
    font-weight: 900;
    background-color: #ffffff00;
    border: none;
    cursor: pointer;
    outline: none;
    width: 40px;
    font-size: 35px;
    position: absolute;
    margin-left: 10%;
    margin-right: 15%;
  }

  .phone-image-container {
    width: 300px;
    /* Adjust to the width of your image */
    height: 500px;
    /* Adjust to the height of your image */
    display: flex;
    /* Use flexbox to center the image */
    justify-content: center;
    align-items: center;
    background: #ffffff00;
    /* White background to match your image */
    border-radius: 20px;
    /* Rounded corners if needed */

  }

  .phone-image {
    max-width: 100%;
    /* Ensure image is contained within the container */
    max-height: 100%;
    /* Ensure image is contained within the container */
  }



  /* YourComponent.css */
  .image-container {
    text-align: center;
    position: relative;
  }

  .image-container img {
    max-width: 100%;
    height: auto;
  }

  .color-buttons {
    margin-top: 20px;
  }

  .color-buttons button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    margin-right: 5px;
  }

  .image-container>button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .image-container>button:first-child {
    left: 10px;
  }

  .image-container>button:last-child {
    right: 10px;
  }

  .phone-image-mobile {
    width: 210px;
    height: 400px;
    opacity: 0;
    /* Start invisible */
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }

  /* Active state for image visibility */
  .phone-image-active {
    opacity: 1;
    transform: scale(1.1);
    /* Slight zoom effect */
  }

  .continue-button {


    background-color: #001b74;
    /* Adjust the color to match your theme */
    color: white;
    border: none;
    padding: 10px 50px;
    border-radius: 20px;
    /* Rounded corners for the button */
    font-size: 1rem;
    /* Adjust the font size as needed */
    cursor: pointer;
    /* Changes the cursor to indicate button is clickable */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* Optional: adds depth */
    border: 1px solid transparent;
    /* Helps retain button shape when clicked */
    margin-top: 20px;
    width: 300px;

  }


}

.sold-out {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-weight: bold;
}


.review-menu {
  display: flex;
  flex-direction: column;
  background: #f7f7f7; /* Light grey background for visibility */
  border-radius: 8px;
  padding: 20px;
  margin: auto; /* Center the review menu */
  margin-top: 100px;

}

.review-row {
  display: flex;
  justify-content: space-between; /* Aligns items on opposite ends */
  border-bottom: 1px solid #dddddd78; /* Adds a light border between rows */
  padding: 10px 0;
}

.review-label {
  font-weight: bold; /* Makes text bold */
  color: #333; /* Dark grey color for text */
}

.review-value {
  color: #666; /* Lighter grey color for text */
}


.use-own-plan {
  background-color: #ffffff; /* White background */
  border: 1px solid #cccccc; /* Light gray border */
  color: #333333; /* Dark gray text */
  padding: 10px 20px; /* Padding around the text */
  text-align: center; /* Center the text inside the button */
  display: block; /* Make the button a block element */
  width: 200px; /* Set a specific width */
  margin: 10px auto; /* Center the button horizontally */
  cursor: pointer; /* Change mouse cursor to pointer when over the button */
  font-size: 16px; /* Set the font size */
  border-radius: 5px; /* Slightly round the corners of the button */
}

.use-own-plan:hover {
  background-color: #f0f0f0; /* Slightly darker background on hover */
}
