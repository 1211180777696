@import url("https://fonts.googleapis.com/css2?family=Alata&family=Jost:wght@100&family=Madimi+One&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");


.landing-page-mobile-section {
  /* background-color: #00254a; */
    color: white;
    text-align: center;
    margin-top: 0px;
  }
  .footer-on-mobile-view{



    margin-top: 30px;
  }

    
  .landing-page-mobile-section h1 {
    font-size: 25px; 
    color: #ffffff;
    padding-top: 20px;
  }
  
  .subtitle {
    font-size: 12px; 
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .feature-tags {
    display: flex;
    justify-content: center;
    gap: 10px; 
    margin-bottom: 20px;
  }
  
  .feature-tags span {

    background-color: #ffffff; 
    color: rgb(0, 12, 91);
    padding: 5px 15px;
    border-radius: 15px; 
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); 
    font-size: 10px;
    font-weight: 600;
    margin: 10px;

  }
  
.tablet-image-container {

  width:50%;
  margin: 0 auto; 

}

.tablet-image-container img {

  width: 100%; 
  height: auto; 
  display: block; 

}





  /*****************************************************************************************************************/

  
  .explore-devices-section {
    margin: 10px;
    height: 480px;
    text-align: center;
    padding: 5px;
    background-color: #f0f0f0; /* Ensure this is the color you want shown if the image can't load */
    border-radius: 18px;
    background-image: url('https://i.imgur.com/j9q8GMY.png'); /* Set the background image */
    background-size: cover; /* Ensure the image covers the entire area */
    background-position: center; /* Center the image within the element */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    margin-top: 25px;
}

  
  .explore-devices-section h2 {

    font-size: 28px; /* Adjust as needed */
    color: #ffffff; /* Or any color as per your design */

  }
  
  .pricing-info {

    font-size: 15px; /* Adjust as needed */
    color: #ffffff; /* Or any color as per your design */

  }
  
  .device-features {

    list-style: none;
    padding: 0;
    margin-bottom: 20px;

  }
  
  .device-features li {

    font-size: 12px; /* Adjust as needed */
    margin-bottom: 5px;
    color: #ffffff;
    font-weight: 600;

  }
  

  
  .navigation-dots {

    text-align: center;
    margin-bottom: 20px;

  }
  
  .dot {

    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;

  }
  
  .active,
  .dot:hover {

    background-color: #717171;

  }
  
  .device-images {
    display: flex;
    justify-content: center; /* Align items in the center */
    align-items: flex-start;
    position: relative; /* Needed for the :after pseudo-element to position correctly */
    padding: 25px 0px 100px; /* Provide some vertical space */

  }

  .device-images::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    bottom: 0;
    width: 2px; /* Line width */
    background-color: #d3d3d3; /* Line color */
  }
  
  .tablet-landing-page-mobile-view{

    display: flex;
    flex-direction: column; /* Stacks image and button vertically */
    align-items: center; /* Centers items horizontally */
    width: 150px;
    height: 200px;
    padding-right: 30px;

  }
  
  
  .phone-landing-page-mobile-view {

    display: flex;
    flex-direction: column; /* Stacks image and button vertically */
    align-items: center; /* Centers items horizontally */
    width: 150px;
    height: 200px;

  }
  
  button {

    margin-top: 10px; /* Adds some space between the image and the button */
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 18px;
    background-color: #ffffff; /* Adjust background color as needed */
    color: #000000; /* Adjust text color for visibility */
    border: none; /* Optional: Removes the border */

  }
  
  /* Additional styles for tablet-img and phone-img if needed */
  .tablet-img-mobile{

    height: 100px; /* Adjust as needed */
    width: 150px;
  } 
  
  
  .phone-img {

    height: 120px; /* Adjust as needed */
    max-width: 100px; /* Ensures the image is responsive */

  }
  



  .tab-link-mobile-view {

    color: #ffffff;
    font-weight: 600;
    border-radius: 12px;
    padding: 5px 8px; /* Add some padding */
    display: inline-block; /* Makes padding effective and allows border-radius to be visible */
    text-decoration: none; /* Optional: Removes underline from link */
    font-size: 15px;
    margin-top: 20px;
    background-color: #001e5f;
  }

  .your-choice-pure-mobile {
    background-color: #ffffff;
    text-align: center;
    transition: transform 0.5s ease-in-out;
  }
  
  
  .first-slide-inner-mobile {
    margin-left: 13%;
    margin-right: 13%;
    background-color: #ffffff;
    border-radius: 15px;
  }
  
  
  .your-choice-pure-mobile h2 {
    padding-top: 15px;
    margin-bottom: 0px;
    color: #003366;
    font-size: 1.5em;
  }
  
  .buttons-container-mobile {
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .buttons-container-mobile p {
    padding: 12px;
    font-weight: bold;
    color: #00087b;
    border-radius: 15px;
    border-color: #000;
    background-color: #e1e1e1;
    margin: 10px;
    font-size: 12px;
  }
  
  .your-choice-pure-mobile button {
    background-color: transparent;
    border: 2px solid #003366;
    border-radius: 20px;
    padding: 10px 20px;
    margin: 0 10px;
    color: #003366;
    font-weight: bold;
    cursor: pointer;
  }
  .your-choice-pure-mobile .tablet-image {
    width: 100%; /* Makes the image responsive to the width of its container */
    height: auto; /* Maintains the aspect ratio */
    margin-top: 10px; /* Keeps the top margin as specified */
  }
  .upper-container-mobile {
    height: 40vh;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: flex-end; /* Aligns the content to the bottom */
    justify-content: flex-start; /* Aligns the content to the left */
    padding: 20px; /* Adds padding inside the container */
    margin: 12px;
    border-radius: 25px;
  }
  .overlay-text {
    color: #ffffff; /* Adjust color as needed */
    font-size: 12px; /* Adjust font size as needed */
    font-weight: bold; /* Optional: if you want the text to be bold */
    margin-bottom: 100px; /* Space from the bottom of the container */
    max-width: 40%; /* Adjust width as needed */
    border-radius: 8px; /* Rounded corners for the text box */
    position: relative; /* This ensures that the button can be absolutely positioned within the text box */
    text-align: left;

  }
  .overlay-text button {
    background-color: #ffffff; /* Example blue background color, change as needed */
    color: rgb(0, 31, 115); /* Text color */
    border: none;
    padding: 6px 12px;
    border-radius: 5px; /* Rounded corners */
    font-size: 10px; /* Mobile-friendly font size */
    position: absolute; /* Absolute positioning relative to the overlay-text div */
    top: 30px; /* Half outside the bottom edge */
    left: 20%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Shadow for depth (optional) */
    cursor: pointer;
    border-radius: 12px;
  }

  .promo-container {
    position: relative;
    width: 100%;
    height: 300px;
    font-family: "Nunito", sans-serif;
    color: white;
    font-family: Arial, sans-serif;
    border-radius: 15px;
  }
  .promo-background {
    margin: 12px;
    border-radius: 15px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/appstore-81cb7.appspot.com/o/random-images%2F5x9Rxk0%20-%20Imgur.png?alt=media&token=6f6b1ef3-081e-4b07-b440-07a55379669b');
    background-size: cover;
  }
  
  .promo-text {
    position: relative;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.863); /* Semi-transparent white */
    color: #001667;
    width: 70%;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    height: 200px; /* Fixed height, but now centering is handled by flex */
    text-align: center;
    border-radius: 10px;
    overflow-y: auto; /* Retains vertical scrolling */
    font-size: 16px;
  }
  
  .promo-text h1 {
    margin: 0; /* Remove top margin */
    color: #000;
    font-size: 20px;
  }
  

  