.header {
  display: flex;
  justify-content: space-between; /* Maintains space between logo and navigation/cart */
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.pageNavigation{
cursor: pointer;
  
}
.logo-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 50px; /* Ensures some spacing from the left edge */
  margin-top: 10px;
}

.logo {
  height: auto;
  max-height: 30px; /* Adjust max-height as needed */
  max-width: 100%;
}

.navigation {
  display: flex;
  align-items: center; /* Aligns items vertically */
  background-color: rgb(241, 241, 241);
  padding: 10px;
  border-radius: 15px;
  margin-left: auto; /* Pushes the navigation to the right */
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
}

.nav-links li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  padding: 5px 10px;
}

.cart {
  display: flex; /* Ensures the cart is also aligned with the nav links */
  align-items: center; /* Aligns the cart icon vertically */
  cursor: pointer;
  margin-left: 20px; /* Adds some space between the nav links and cart */
}

.cart img {
  height: auto;
  max-height: 30px;
}

.dropdown {
  position: absolute;
  background-color: #fff;
  padding: 12px 16px;
  border-radius: 8px;
  z-index: 1;
  top: 100%;
  left: 0;
}

.dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown ul li a {
  color: #333;
  padding: 10px 15px;
  text-decoration: none;
  display: block;
}

.dropdown ul li a:hover {
  background-color: #f1f1f1;
}

.nav-links li {
  position: relative;
}

.nav-links li .dropdown-icon::after {
  content: '';
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
  border-top: 5px solid black;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-bottom: none;
}



@media only screen and (min-width: 700px) and (max-width: 1100px) {
  
  .cart img {
    max-height: 25px;
  }
  .nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
    font-size: 13px

  }
  .logo {
    height: auto;
    max-height: 18px; /* Adjust max-height as needed */
    max-width: 90%;
  }
  
  
}

