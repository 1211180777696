.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: fadeIn 0.3s forwards; /* Smooth fade-in effect */
}

@keyframes fadeIn {
  to {
      opacity: 1;
  }
}

.modal-content {
  background: #ffffff;
  padding: 25px;
  border-radius: 10px;
  width: 350px;
  max-width: 90%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow */
  text-align: left; /* Aligns content to the left */
}

.modal-content h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
}

.modal-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.modal-content ul li {
  padding: 8px 0;
  border-bottom: 1px solid #eee; /* Divider line between specs */
  color: #555;
  font-size: 0.95em;
}

.modal-content ul li:last-child {
  border-bottom: none; /* Removes bottom border from the last item */
}

.modal-content button {
  margin-top: 20px;
  padding: 10px 15px;
  background-color: #0073e6;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.95em;
  width: 100%;
  text-align: center;
  transition: background-color 0.2s ease;
}

.modal-content button:hover {
  background-color: #005bb5;
}


/*





*/