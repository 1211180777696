.popupMenu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.popupMenu-content {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  max-width: 600px; /* Adjust based on your preference */
  width: 90%; /* Responsive width */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease; /* Smooth transition for modal popup */
  position: relative; /* Needed for absolute positioning of children */

}

.popupMenu-content h3 {
  color: #333;
  font-size: 18px;
  margin-bottom: 15px;
}

.popupMenu-content button {
  margin: 5px;
  padding: 12px 20px; /* More padding for better touch target */
  border-radius: 8px; /* Rounded corners */
  border: 1px solid #ddd; /* Subtle border */
  background-color: #f9f9f9; /* Lighter background */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Smooth transitions for button interactions */
  font-weight: bold; /* Make text a bit bolder */
}

.popupMenu-content button:hover {
  background-color: #007bff; /* Change for hover */
  color: white; /* Text color change */
  border-color: #007bff; /* Border color change to match background */
}

.popupMenu-content button.selected {
  background-color: #0056b3; /* Deeper blue for selected */
  color: white;
  border-color: #0056b3; /* Border color to match */
}

.done-button {
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  background-color: #0056b3;
  color: rgb(82, 82, 82);
  border: 1px solid #004080;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s; /* Smooth transition for hover effect */
}

.done-button:hover {
  background-color: #004080; /* Slightly darker on hover */
  border-color: #002f4b; /* Adjust border color on hover */
}

.change-plan-popup {
  margin-bottom: 15px;
}

.payment-option-container {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  transition: box-shadow 0.3s; /* Smooth transition for shadow */
}

.payment-option-container:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Slight elevation on hover */
}

.payment-option-details {
  background-color: #f7f7f7;
  border-left: 4px solid #007bff;
  padding: 10px;
  margin-top: 10px;
  border-radius: 0 8px 8px 0; /* Rounded corners on the right */
  display: none; /* Initially hidden */
}

.payment-option-container.selected .payment-option-details {
  display: block; /* Show on selection */
}

.payment-option-button {
  background: transparent;
  border: none;
  text-align: left;
  width: 100%;
  padding: 10px;
}

.payment-option-button:hover,
.payment-option-button:focus {
  background-color: #007bff; /* Light background on hover/focus */
}


@media (max-width: 600px) {
  .popupMenu-content {
    width: 95%; /* Increase the width to cover more of the screen */
    padding: 20px; /* Decrease padding to save space */
    max-width: none; /* Override any max-width */
    
  }

  .popupMenu-content h3 {
    font-size: 16px; /* Decrease font size to save space */
  }

  .popupMenu-content button,
  .done-button {
    font-size: 10px; /* Smaller buttons */
    padding: 10px 10px; /* Smaller padding */
  }
  
  .payment-option-button {
    padding: 12px; /* Adjust the padding for a more compact layout */
  }

  .change-plan-popup button {
    font-size: 14px; /* Smaller text for smaller screens */
  }
}

/* Media Query for screens smaller than or equal to 360px */
@media (max-width: 360px) {
  .popupMenu-content {
    width: 100%; /* Use the full width for very small screens */
    border-radius: 0; /* Optional: remove border radius for full-width on small screens */
    padding: 8px; /* Even smaller padding */
  }

  .popupMenu-content button,
  .done-button {
    font-size: 10px; /* Smaller buttons */
    padding: 10px 10px; /* Smaller padding */
  }
}
.close-button {
  position: absolute;
  top: 10px; /* Adjust as necessary */
  right: 10px; /* Adjust as necessary */
  background: transparent;
  border: none;
  font-size: 24px; /* Large 'X' symbol */
  cursor: pointer;
  padding: 0;
  line-height: 1;
  color: #333; /* Adjust color as necessary */
}
