.mobile-selection-page {
  min-height: 100vh;
  background-color: #ffffff;
  -webkit-tap-highlight-color: transparent;
}

.mobile-hero-section {
  background: linear-gradient(135deg, #002349 0%, #003366 100%);
  padding: 60px 15px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.mobile-hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    radial-gradient(circle at 20% 30%, rgba(0, 174, 239, 0.15) 0%, transparent 50%),
    radial-gradient(circle at 80% 70%, rgba(0, 174, 239, 0.15) 0%, transparent 50%);
  opacity: 0.8;
}

.mobile-hero-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
}

.mobile-hero-content h1 {
  color: #ffffff;
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 20px;
  line-height: 1.2;
}

.mobile-hero-content p {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.2rem;
  line-height: 1.6;
}

.mobile-selection-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 60px 20px;
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 40px;
  align-items: start;
}

.mobile-preview {
  position: sticky;
  top: 20px;
  background: #ffffff;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mobile-image-container {
  background: #f8f9fa;
  padding: 40px;
  border-radius: 10px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 200px; /* Add a fixed width */
}

.mobile-image {
  width: 100%; /* Scale image to fit container width */
  height: auto; /* Maintain aspect ratio */
  max-height: 100%; /* Limit image height to container */
  object-fit: contain; /* Ensure the image scales without cropping */
}

.selected-options {
  padding: 20px;
  background: #f8f9fa;
  border-radius: 10px;
}

.selected-options h3 {
  color: #002349;
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-weight: 600;
}

.selection-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #e2e8f0;
}

.selection-item:last-child {
  border-bottom: none;
}

.selection-item span:first-child {
  color: #4a5568;
  font-weight: 500;
}

.selection-item span:last-child {
  color: #002349;
  font-weight: 600;
}

.mobile-options {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.options-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
}

.option-section {
  background: #ffffff;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.option-section:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.option-section h2 {
  color: #002349;
  font-size: 1.8rem;
  margin-bottom: 25px;
  font-weight: 700;
  text-align: center;
}

.color-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
  gap: 20px;
  justify-items: center;
  padding: 10px;
}

.color-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid transparent;
  cursor: pointer;
  position: relative;
  transition: transform 0.3s ease;
  -webkit-tap-highlight-color: transparent;
}

.color-button:hover {
  transform: scale(1.1);
}

.color-button.selected {
  border-color: #00aeef;
  transform: scale(1.1);
}

.color-name {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 0.9rem;
  color: #4a5568;
}

.payment-options, .plan-options {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.option-button {
  background: #f8f9fa;
  border: 2px solid transparent;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
  -webkit-tap-highlight-color: transparent;
  min-height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.option-button:hover {
  background: #f1f5f9;
  transform: translateY(-2px);
}

.option-button.selected {
  border-color: #00aeef;
  background: #e6f7ff;
  transform: translateY(-2px);
}

.option-button h3, .option-button h4 {
  color: #002349;
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: 600;
}

.option-button .price {
  color: #00aeef;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 5px;
}

.option-button .description {
  color: #4a5568;
  font-size: 0.9rem;
}

.plan-type {
  margin-bottom: 30px;
}

.plan-type:last-child {
  margin-bottom: 0;
}

.plan-type h3 {
  color: #002349;
  font-size: 1.4rem;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
}

.data-plans {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.carrier-option {
  text-align: center;
  background: #f8f9fa;
  border-radius: 12px;
  padding: 25px;
  margin-top: 10px;
}

.carrier-option .original-price {
  color: #dc3545;
  text-decoration: line-through;
  margin-right: 10px;
  font-size: 1.2rem;
}

.carrier-option .current-price {
  color: #00aeef;
  font-weight: 700;
  font-size: 1.6rem;
}

.continue-button {
  background: #00aeef;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  padding: 16px 25px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  margin-top: 25px;
  min-height: 54px;
  -webkit-tap-highlight-color: transparent;
}

.continue-button:hover {
  background: #0095cc;
  transform: translateY(-2px);
}

.continue-button span {
  transition: transform 0.3s ease;
}

.continue-button:hover span {
  transform: translateX(5px);
}

@media (max-width: 1200px) {
  .mobile-selection-container {
    grid-template-columns: 1fr;
    padding: 30px 15px;
  }

  .mobile-preview {
    position: relative;
    top: 0;
  }

  .options-row {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .mobile-hero-section {
    padding: 40px 15px;
  }

  .mobile-hero-content h1 {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .mobile-hero-content p {
    font-size: 1rem;
  }

  .mobile-selection-container {
    padding: 20px 15px;
    gap: 25px;
  }

  .mobile-preview {
    padding: 15px;
  }

  .mobile-image-container {
    height: 250px;
    padding: 15px;
  }

  .option-section {
    padding: 20px 15px;
  }

  .option-section h2 {
    font-size: 1.4rem;
    margin-bottom: 20px;
  }

  .color-options {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    padding: 5px;
  }

  .color-button {
    width: 50px;
    height: 50px;
  }

  .color-name {
    font-size: 0.8rem;
    bottom: -20px;
  }

  .data-plans {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .option-button {
    padding: 15px;
  }

  .option-button h3, .option-button h4 {
    font-size: 1.1rem;
    margin-bottom: 8px;
  }

  .option-button .price {
    font-size: 1.3rem;
  }

  .option-button .description {
    font-size: 0.85rem;
  }

  .carrier-option {
    padding: 20px 15px;
  }

  .carrier-option .current-price {
    font-size: 1.4rem;
  }

  .continue-button {
    padding: 14px 20px;
    font-size: 1.1rem;
    min-height: 48px;
  }
}

@media (max-width: 480px) {
  .mobile-hero-section {
    padding: 30px 10px;
  }

  .mobile-hero-content h1 {
    font-size: 1.8rem;
  }

  .mobile-selection-container {
    padding: 15px 10px;
  }

  .mobile-preview {
    padding: 12px;
  }

  .mobile-image-container {
    height: 200px;
    padding: 12px;
  }

  .selected-options h3 {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }

  .selection-item {
    font-size: 0.9rem;
    padding: 8px 0;
  }

  .option-section {
    padding: 15px 12px;
  }

  .option-section h2 {
    font-size: 1.3rem;
    margin-bottom: 15px;
  }

  .color-options {
    gap: 12px;
  }

  .color-button {
    width: 45px;
    height: 45px;
  }

  .color-name {
    font-size: 0.75rem;
    bottom: -18px;
  }

  .option-button {
    padding: 12px;
  }

  .option-button h3, .option-button h4 {
    font-size: 1rem;
  }

  .option-button .price {
    font-size: 1.2rem;
  }

  .option-button .description {
    font-size: 0.8rem;
  }

  .plan-type h3 {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }

  .carrier-option {
    padding: 15px 12px;
  }

  .carrier-option .current-price {
    font-size: 1.3rem;
  }

  .continue-button {
    padding: 12px 15px;
    font-size: 1rem;
    min-height: 44px;
    margin-top: 20px;
  }
}
