
/* .main-container {
    margin-top: 3%;

    display: flex;
    width: 100%;
  }
   */
 
.review-bag-container {
    width: 70%;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    text-align: left;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 0.5em;
  }
  
  .item-count {
    text-align: left;
    color: #555;
    margin-bottom: 1em;
    margin-left: 1em;
  }
  
  .product-details {
    padding: 20px;
    margin-bottom: 1em;
  }
  
  h2 {
    margin: 0;
  }
  
  .product-description {
    color: #555;
  }
  
  .quantity-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  
  .price {
    font-weight: bold;
  }
  
  .data-plan {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    margin-top: 10px;
  }
  
  .cost-summary {
    border-top: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 1em;
  }
  
  .cost-summary p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
  }
  
  .total {
    font-size: 1.2em;
    font-weight: bold;
  }
  
  .pay-now-button {
    display: block;
    width: 30%;
    padding: 10px;
    font-size: 1em;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
  }
  
  .pay-now-button:hover {
    background-color: #0056b3;
  }
  

  .left-side {
    width: 30%;
    margin-left: 8%;
    margin-top: 8%;

   
  }

  .device-image{
 /* Add styles for the left side here */
    /* For example, a background color or padding: */
    background-color: #f8f8f8;
    height: 500px;

  }

  .view-finance-button {
    display: inline-block; /* Allows you to set width and height */
    padding: 10px 20px; /* Size of the button */
    font-size: 1rem; /* Text size within the button */
    color: #ffffff; /* Text color */
    background-color: #007bff; /* Button color */
    border: none; /* Removes the border */
    border-radius: 20px; /* Rounded corners */
    text-align: center;
    cursor: pointer; /* Changes cursor to indicate the element is clickable */
    transition: background-color 0.3s ease; /* Smooth transition for background color */
    text-decoration: none; /* In case it's an anchor tag */
    margin-top: 20px; /* Add margin for spacing from the elements above */
  }
  
  .view-finance-button:hover {
    background-color: #0056b3; /* Darker shade on hover for interactive feedback */
  }
  