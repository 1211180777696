@import url("https://fonts.googleapis.com/css2?family=Alata&family=Jost:wght@100&family=Madimi+One&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

.checkout-page {
  display: flex;
  max-width: 1800px;
  width: 70%;
  margin: 3rem auto;
  box-shadow: 0 12px 36px rgba(0, 35, 73, 0.15);
  border-radius: 30px;
  overflow: hidden;
  font-family: "Nunito", sans-serif;
  position: relative;
  min-height: 800px;
  background: #ffffff;
}

.checkout-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: linear-gradient(90deg, #00aeef, #66d4ff);
  z-index: 1;
}

.payment-section {
  flex: 2; /* Makes this section take up more space */
  padding: 2rem;
}

.order-summary-section {
  flex: 1; /* Makes this section take up less space */
  padding: 1rem;
  background-color: #f8f9fa;
  border-left: 1px solid rgba(0, 174, 239, 0.15);
}
.payment-section {
  background: #ffffff;
  color: #002349;
  flex: 1.5;
  
}

.order-summary-section {
  background-color: #f8f9fa;
  border-left: 1px solid rgba(0, 174, 239, 0.15);
  flex: 1;
  width: 450px;
}

.form-section {
  margin-top: 1rem;
  max-width: 80%;
}

/* Card Information Section */
.card-info-section {
  background: #ffffff;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border: 1px solid #e2e8f0;
  margin-bottom: 1rem;
}

.card-info-section h3 {
  color: #002349;
  font-size: 1rem;
  font-weight: 800;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e2e8f0;
  letter-spacing: 0.3px;
}

.card-fields {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  gap: 0.8rem;
}

/* Billing Fields Layout */
.billing-section {
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border: 1px solid #e2e8f0;
  margin-bottom: 1rem;
}

.billing-section h3 {
  color: #002349;
  font-size: 1rem;
  font-weight: 800;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e2e8f0;
  letter-spacing: 0.3px;
}

.billing-fields {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.8rem;
}

.billing-fields .name-fields {
  grid-column: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.8rem;
}

.billing-fields .address {
  grid-column: 1 / -1;
}

.billing-fields .address-line2 {
  grid-column: 1 / -1;
}

.billing-fields .city {
  grid-column: span 2;
}

.billing-fields .state {
  grid-column: span 1;
}

.billing-fields .zip {
  grid-column: span 1;
}

/* Contact Fields */
.contact-fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;
}

/* Form Labels */
.form-section label {
  display: block;
  color: #1e293b;
  font-size: 0.9rem;
  font-weight: 700;
  margin-bottom: 0.3rem;
  letter-spacing: 0.2px;
}

/* Input Styling */
.form-section input[type="text"] {
  padding: 0.8rem 1rem;
  height: 20px;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  background: #ffffff;
  color: #1e293b;
  font-family: "Nunito", sans-serif;
  font-size: 0.95rem;
  transition: all 0.2s ease;
  width: 90%;
  box-shadow: 0 2px 4px rgba(148, 163, 184, 0.05);
}

.form-section input[type="text"]:hover {
  border-color: #94a3b8;
  box-shadow: 0 2px 4px rgba(148, 163, 184, 0.1);
}

.form-section input[type="text"]:focus {
  outline: none;
  border-color: #00aeef;
  box-shadow: 0 0 0 3px rgba(0, 174, 239, 0.1);
  transform: translateY(-1px);
}

.form-section input[type="text"]::placeholder {
  color: #94a3b8;
  font-weight: 500;
  font-size: 0.95rem;
}

/* Shipping Section */
.shipping-section {
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border: 1px solid #e2e8f0;
  margin-top: 1rem;
}

.shipping-section h3 {
  color: #002349;
  font-size: 1rem;
  font-weight: 800;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e2e8f0;
  letter-spacing: 0.3px;
}

.shipping-fields {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.8rem;
}

.shipping-fields .name-field {
  grid-column: span 2;
}

.shipping-fields .address {
  grid-column: 1 / -1;
}

.shipping-fields .address-line2 {
  grid-column: 1 / -1;
}

.shipping-fields .city {
  grid-column: span 2;
}

.shipping-fields .state {
  grid-column: span 1;
}

.shipping-fields .zip {
  grid-column: span 1;
}

/* Checkbox Styling */
.checkbox-container {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin: 1rem 0;
  padding: 0.8rem;
  background: #f8fafc;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
}

.checkbox-container input[type="checkbox"] {
  width: 1.2rem;
  height: 1.2rem;
  border: 2px solid #e2e8f0;
  border-radius: 4px;
  cursor: pointer;
  accent-color: #00aeef;
}

.checkbox-container span {
  color: #1e293b;
  font-size: 0.95rem;
  font-weight: 600;
}

.checkbox-container a {
  color: #00aeef;
  text-decoration: none;
  font-weight: 600;
}

.checkbox-container a:hover {
  text-decoration: underline;
}

.payment-section{
  width: 70%;
}


/* Headers */
.payment-section h1,
.order-summary-section h1 {
  color: #1e293b;
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  position: relative;
  display: inline-block;
}

.payment-section h1::after,
.order-summary-section h1::after {
  content: '';
  position: absolute;
  bottom: -12px;
  left: 0;
  width: 60px;
  height: 4px;
  background: linear-gradient(90deg, #00aeef, #66d4ff);
  border-radius: 2px;
}

/* Order Summary Styles */
.order-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  margin-bottom: 1rem;
  background: #ffffff;
  border-radius: 12px;
  transition: all 0.3s ease;
  border: 1px solid #e2e8f0;
}

.order-item:hover {
  transform: translateX(5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.order-item-1 {
  font-weight: 700;
  color: #1e293b;
  font-size: 1rem;
  letter-spacing: 0.2px;
}

.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  margin-bottom: 1rem;
  background: #ffffff;
  border-radius: 12px;
  transition: all 0.3s ease;
  border: 1px solid #e2e8f0;
}

.total-items-1 {
  font-weight: 800;
  font-size: 1rem;
  color: #002349;
}

.total-items-2 {
  font-weight: 800;
  font-size: 1rem;
  color: #00aeef;
}

/* Buttons */
.edit-button {
  background: transparent;
  border: 2px solid #00aeef;
  color: #00aeef;
  padding: 0.8rem 1.5rem;
  border-radius: 12px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 1.5rem;
  font-size: 0.95rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.edit-button:hover {
  background: #00aeef;
  color: #ffffff;
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 174, 239, 0.3);
}

.order-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
  margin-top: 1.5rem;
}

.order-details span {
  font-size: 1rem;
  color: #64748b;
}

.order-details button,
.promo-code-check-btn {
  background: #00aeef;
  color: #ffffff;
  border: none;
  padding: 1rem 2rem;
  border-radius: 12px;
  font-weight: 700;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.order-details button:hover,
.promo-code-check-btn:hover {
  background: #0095cc;
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 174, 239, 0.3);
}

/* Promo Code */
.promo-code-container {
  display: flex;
  gap: 0.8rem;
  margin: 1.5rem 0;
}

.promo-code-input {
  flex-grow: 1;
  padding: 0.8rem 1rem;
  height: 42px;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  font-family: "Nunito", sans-serif;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  background: #ffffff;
}

.promo-code-input:focus {
  outline: none;
  border-color: #00aeef;
  box-shadow: 0 0 0 3px rgba(0, 174, 239, 0.1);
}

.promo-code-check-btn {
  padding: 0 1.5rem;
  min-width: 100px;
  height: 42px;
}

/* Dropdown */
.custom-dropdown select {
  padding: 0.8rem 1rem;
  height: 42px;
  border-radius: 12px;
  border: 2px solid #e2e8f0;
  font-family: "Nunito", sans-serif;
  font-size: 0.95rem;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #ffffff;
  color: #002349;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem top 50%;
  background-size: 0.8rem auto;
  padding-right: 2.5rem;
  min-width: 120px;
}

.custom-dropdown select:hover {
  border-color: #00aeef;
}

.custom-dropdown select:focus {
  outline: none;
  border-color: #00aeef;
  box-shadow: 0 0 0 3px rgba(0, 174, 239, 0.1);
}

@media (max-width: 2800px) {
  .checkout-page {
    display: flex;
    max-width: 1800px;
    width: 50%;
    margin: 3rem auto;
    box-shadow: 0 12px 36px rgba(0, 35, 73, 0.15);
    border-radius: 30px;
    overflow: hidden;
    font-family: "Nunito", sans-serif;
    position: relative;
    min-height: 800px;
    background: #ffffff;
  }
}

@media (max-width: 2000px) {
  .checkout-page {
    display: flex;
    max-width: 1800px;
    width: 70%;
    margin: 3rem auto;
    box-shadow: 0 12px 36px rgba(0, 35, 73, 0.15);
    border-radius: 30px;
    overflow: hidden;
    font-family: "Nunito", sans-serif;
    position: relative;
    min-height: 800px;
    background: #ffffff;
  }
}



@media (max-width: 1600px) {


  .checkout-page {
    width: 95%;
  }
  
  .payment-section,
  .order-summary-section {
    padding: 1.5rem;
  }
  .order-summary-section {
    background-color: #f8f9fa;
    border-left: 1px solid rgba(0, 174, 239, 0.15);
    flex: 1;
    width: 300px;
  }
  
}

@media (max-width: 1200px) {
  .billing-fields,
  .shipping-fields {
    grid-template-columns: repeat(2, 1fr);
  }

  .billing-fields .city,
  .shipping-fields .city {
    grid-column: 1;
  }

  .billing-fields .state,
  .shipping-fields .state {
    grid-column: 2;
  }

  .billing-fields .zip,
  .shipping-fields .zip {
    grid-column: 1;
  }

  .order-summary-section {
    background-color: #f8f9fa;
    border-left: 1px solid rgba(0, 174, 239, 0.15);
    flex: 1;
    width: 90%;
  }
}

@media (max-width: 768px) {
  .checkout-page {
    flex-direction: column-reverse; /* Place order summary above payment section */
    margin: 0;
    border-radius: 0;
    width: 100%;
  }

  .order-summary-section {
    order: -1; /* Ensures the order summary section is placed at the top */
  }

  .payment-section,
  .order-summary-section {
    padding: 1rem;
  }

  .order-summary-section {
    border-left: none;
    border-top: 1px solid rgba(0, 174, 239, 0.15);
  }

  .card-fields,
  .billing-fields,
  .shipping-fields,
  .contact-fields {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .billing-fields .name-fields {
    grid-template-columns: 1fr;
  }

  .promo-code-container {
    flex-direction: column;
  }

  .promo-code-input,
  .promo-code-check-btn {
    width: 100%;
  }

  
.form-section {
  margin-top: 2rem;
  max-width: 100%;
}
}



