/* General container styling */
.app-selection, .app-selection-mobile {
    text-align: center;
    margin: 80px auto;
    max-width: 1200px; /* Control max width */
}

/* Title styling */
.app-selection-mobile h1, .app-selection h1 {
    margin-top: 2%;
    margin-bottom: 3%;
    color: #00679f; /* Choose a color scheme */
    font-size: 2.5em;
}

/* Styling for images within the app selection */
.app-selection img, .app-selection-mobile img {
    max-width: 100%;
    height: auto;
}

/* App categories styling */
.app-category {
    margin-bottom: 40px;
}

.app-category h2 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
    text-align: left; /* Align category titles left */
}

/* App item grid */
.app-category-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px; /* Space between items */
}

/* Individual app item styling */
.app-item-plus {
    width: 80px; /* Fixed width for items */
    height: 100px; /* Fixed height to include name */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    border-radius: 50%; /* Circular icons */
    transition: transform 0.2s; /* Smooth hover effect */
    font-weight: 600;
}

.app-item-plus:hover {
    transform: scale(1.1); /* Enlarge on hover */
}

.app-item-plus img {
    max-width: 60px;
    max-height: 60px;
    border-radius: 50%; /* Ensure images are circular */
}

.app-item-plus p {
    margin-top: 5px;
    font-size: 12px; /* Smaller font for names */
    text-align: center;
    color: #333;
}

/* Scroll to top button styling */
.scroll-to-top-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #00679f;
    border: none;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    transition: background-color 0.2s;
}

.scroll-to-top-btn img {
    width: 24px;
    height: 24px;
}

.scroll-to-top-btn:hover {
    background-color: #049bec;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .app-selection, .app-selection-mobile {
        margin: 40px auto;
        padding: 0 20px;
    }

    .app-selection-mobile h1, .app-selection h1 {
        font-size: 2em;
    }

    .app-item-plus {
        width: 60px;
        height: 80px;
        margin: 5px;
    }

    .app-item-plus img {
        max-width: 50px;
        max-height: 50px;
    }

    .app-item-plus p {
        font-size: 10px;
    }
}

/* Smaller screens (like phones) */
@media (max-width: 480px) {
    .app-item-plus {
        width: 50px;
        height: 70px;
    }

    .app-item-plus img {
        max-width: 40px;
        max-height: 40px;
    }

    .app-item-plus p {
        font-size: 9px;
    }
}
