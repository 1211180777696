@import url("https://fonts.googleapis.com/css2?family=Alata&family=Jost:wght@100&family=Madimi+One&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

.app-store-builder {
  text-align: center;
  padding: 20px;
  color: #fafafa;
  margin-top: 2%;
  background: radial-gradient(circle, #12058b, #3b3b3b);
  font-family: "Nunito", sans-serif;
}

.app-store-builder p {
  color: #fafafa;
}

.app-store-container {
  display: flex;
  justify-content: space-around;
}

.app-store-upper-container {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align items vertically center */
  width: 85%; /* Adjust the width as needed */
  margin: 0 auto; /* Center the container horizontally */
}

.left-upper-container {
  display: flex;
  justify-content: flex-start;
  width: 50%; /* Adjust the width as needed */
}

.right-upper-container {
  display: flex;
  justify-content: flex-end;
  width: 50%; /* Adjust the width as needed */
}

.search-bar {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 80%;
  display: flex; /* Ensure the elements inside are flex items */
  justify-content: flex-start; /* Align items to the start (left) */
}

.search-bar input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 30px;
  flex: 1; /* Allow the input to take up available space */
}

.search-bar button {
  padding: 10px 20px;
  border: none;
  color: rgb(0, 34, 97);
  border-radius: 15px;
  margin-left: 10px;
  cursor: pointer;
  background-color: #24dae0;
  border-radius: 15px;
  color: #ffffff;
  font-weight: bold;
}

.app-list {
  width: 40%;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  max-height: 555px;
  overflow-y: auto;
}

.app-category-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
}

/* Custom scrollbar styles */
.app-list::-webkit-scrollbar {
  width: 8px;
}
.app-store-builder h1{
color: #f7f7f7;

}
.app-list::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.app-list::-webkit-scrollbar-thumb {
  background-color: #fdfdfd;
  border-radius: 10px;
  border: 2px solid rgb(255, 255, 255);
}

.app-list h2 {
  color: #f5f5f5;
}

.app-item {
  width: 60px;
  height: 60px;
  margin: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  transition: transform 0.2s ease-in-out; /* Add transition for zoom effect */
}

.app-item:hover {
  transform: scale(1.3); /* Zoom effect on hover */
}

.app-item img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.drop-area {
  width: 40%;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
}

.mobile-frame {
  position: relative;
  width: 290px;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #a8a4a100;
  border-radius: 35px;
  transition: transform 0.2s ease-in-out; /* Add transition for hover effect */
}



@keyframes blinkPulse {
  0% {
    transform: scale(1);
    background-color: transparent;
  }
  50% {
    transform: scale(1.05);
    background-color: rgba(255, 255, 255, 0.2);
  }
  100% {
    transform: scale(1);
    background-color: transparent;
  }
}

.mobile-frame.drag-hover {
  animation: blinkPulse 2s infinite;
}
.frame-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2; /* Ensure the frame is on top */
  pointer-events: none; /* Allow interactions with underlying elements */
}

.dropped-items-container {
  position: relative;
  width: 300px;
  height: 580px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 60px;
  gap: 5px;
  padding: 30px;
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 1; /* Ensure items are under the frame */
}

/* Custom scrollbar styles for the dropped items container */
.dropped-items-container::-webkit-scrollbar {
  width: 8px;
}

.dropped-items-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.dropped-items-container::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.1);
}

.dropped-item {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: auto;
}

.dropped-item img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.dropped-item .delete-btn {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  background: red;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
}

.dropped-item:hover .delete-btn {
  opacity: 1;
}

form {
  margin-top: 20px;
}

form input,
form button {
  padding: 10px;
  margin: 5px;
  border-radius: 15px;
}

form button {
  background-color: #eeeeee;
  color: rgb(0, 6, 92);
  cursor: pointer;
}

.apps-text {
  background-color: #dee6e7;
  padding: 10px;
  margin: 5px;
  border-radius: 15px;
  color: #ffffff;
  font-weight: bold;
}

h2 {
  color: #fafafa;
}

.app-name-slider {
  margin-top: 3px;
  color: #ffffff;
  font-size: 10px;
  max-width: 60px;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.app-name-slider:hover {
  overflow: visible;
}

.app-name-slider span {
  display: inline-block;
  position: relative;
  animation: none;
}

.app-name-slider:hover span {
  animation: slideText 3s linear infinite;
  background: rgba(18, 5, 139, 0.9);
  padding: 0 5px;
}

@keyframes slideText {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
