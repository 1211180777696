.product-page {
  min-height: 100vh;
  background-color: #ffffff;
}

.product-hero-section {
  background: linear-gradient(135deg, #002349 0%, #003366 100%);
  padding: 80px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.product-hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    radial-gradient(circle at 20% 30%, rgba(0, 174, 239, 0.15) 0%, transparent 50%),
    radial-gradient(circle at 80% 70%, rgba(0, 174, 239, 0.15) 0%, transparent 50%);
  opacity: 0.8;
}

.product-hero-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
}

.product-hero-content h1 {
  color: #ffffff;
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 20px;
  line-height: 1.2;
}

.product-hero-content p {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.2rem;
  line-height: 1.6;
}

.product-grid {
  max-width: 1400px;
  margin: 0 auto;
  padding: 60px 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  justify-items: center;
  align-items: stretch;
}

.product-card {
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.product-image {
  background: #f8f9fa;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-image img {
  max-width: 90%;
  max-height: 90%;
  width: auto;
  height: auto;
  object-fit: contain;
}

.product-details {
  padding: 30px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.product-details h2 {
  font-size: 1.8rem;
  color: #002349;
  margin-bottom: 20px;
  font-weight: 700;
  text-align: center;
}

.product-price {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  text-align: center;
}

.original-price {
  color: #dc3545;
  text-decoration: line-through;
  font-size: 1.1rem;
}

.current-price {
  color: #002349;
  font-size: 2rem;
  font-weight: 700;
}

.monthly-price {
  color: #6c757d;
  font-size: 1rem;
}

.product-features {
  list-style: none;
  padding: 0;
  margin: 0 0 30px 0;
  text-align: left;
}

.product-features li {
  margin-left: 50px;

  color: #4a5568;
  padding: 8px 0;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}

.product-features li::before {
  content: '✓';
  color: #00aeef;
  font-weight: bold;
}


.carrier {
  color: #00aeef;
  font-weight: 600;
  margin-left: 5px;
}

.product-cta {
  width: 100%;
  background: #002349;
  
  color: #ffffff;
  border: none;
  border-radius: 10px;
  padding: 15px 25px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: auto;
}

.product-cta:hover {
  background: #0095cc;
  transform: translateY(-2px);
}

.product-cta span {
  transition: transform 0.3s ease;
}

.product-cta:hover span {
  transform: translateX(5px);
}


.product-cta.more-details {
  background: none;
  border: none;
  color: #0073e6;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.9em;
  margin-top: 10px;
}

.product-cta.more-details:hover {
  text-decoration: none;
}


.spacer {
  height: 20px; /* Adjust height as needed */
  width: 100%; /* Optional, makes it take full width */
}


@media (max-width: 768px) {
  .product-hero-content h1 {
    font-size: 2.5rem;
  }

  .product-hero-content p {
    font-size: 1.1rem;
  }

  .product-grid {
    padding: 40px 20px;
    gap: 30px;
  }

  .product-card {
    max-width: 280px;
  }

  .product-image {
    height: 200px;
  }
}

@media (max-width: 480px) {
  .product-hero-content h1 {
    font-size: 2rem;
  }

  .product-hero-content p {
    font-size: 1rem;
  }

  .product-details h2 {
    font-size: 1.5rem;
  }

  .current-price {
    font-size: 1.8rem;
  }

  .product-card {
    max-width: 100%;
  }

  .product-image {
    height: 180px;
  }
}
