.confirmation-page {
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    font-family: 'Helvetica', sans-serif;
    background: #ffffff;
    border-radius: 10px;
    animation: slideIn 0.5s ease-in-out;
  }
  
  h1, h2, h3 {
    color: #333;
    text-align: center;
    margin-bottom: 12px;
    font-weight: bold;
  }
  
  p {
    color: #555;
    line-height: 1.5;
    margin: 6px 0;
  }
  
  .order-section {
    margin: 20px 0;
    border-top: 1px solid #ddd;
    padding-top: 15px;
  }
  
  .section {
    margin: 15px 0;
    padding: 12px;
    background: #f9f9f9;
    border-radius: 8px;
    transition: transform 0.2s;
  }
  
  .section:hover {
    transform: translateY(-3px);
  }
  
  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .btn {
    padding: 10px 20px;
    font-size: 14px;
    margin: 10px 0;
    width: 100%;
    max-width: 200px;
    background: linear-gradient(90deg, #007BFF, #0056b3);
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, background 0.3s;
  }
  
  .btn:hover {
    background: linear-gradient(90deg, #0056b3, #007BFF);
    transform: scale(1.05);
  }
  
  .print-btn {
    background: linear-gradient(90deg, #28a745, #218838);
  }
  
  .save-pdf-btn {
    background: linear-gradient(90deg, #ffc107, #e0a800);
  }
  
  .home-btn {
    background: linear-gradient(90deg, #17a2b8, #138496);
  }
  
  /* Animation for sliding in effect */
  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .confirmation-page {
      padding: 15px;
    }
  
    h1, h2, h3 {
      font-size: 20px;
      margin-bottom: 10px;
    }
  
    p {
      font-size: 14px;
    }
  
    .order-section {
      margin: 15px 0;
    }
  
    .section {
      padding: 10px;
      margin: 10px 0;
    }
  
    .btn {
      font-size: 13px;
      padding: 8px 15px;
    }
  
    .actions {
      flex-direction: column;
      align-items: center;
    }
  }
  
  @media (max-width: 480px) {
    h1, h2, h3 {
      font-size: 18px;
    }
  
    .btn {
      font-size: 12px;
      padding: 7px 12px;
      max-width: 180px;
    }
  }
  