@import url("https://fonts.googleapis.com/css2?family=Alata&family=Jost:wght@100&family=Madimi+One&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 35, 73, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: "Nunito", sans-serif;
  backdrop-filter: blur(5px);
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.popup-content {
  background: white;
  padding: 40px;
  border-radius: 20px;
  width: 90%;
  max-width: 440px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  position: relative;
  text-align: center;
  animation: slideUp 0.4s ease-out;
}

@keyframes slideUp {
  from { 
    opacity: 0;
    transform: translateY(20px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #002349;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.close-button:hover {
  background: #f0f4f8;
}

.popup-header {
  margin-bottom: 20px;
}

h2 {
  font-size: 32px;
  color: #002349;
  font-weight: 800;
  margin: 0 0 8px 0;
}

.thank-u-text {
  font-size: 18px;
  font-weight: 600;
  color: #4a5568;
  margin-bottom: 24px;
}

.offer-text {
  font-size: 18px;
  color: #4a5568;
  margin-bottom: 30px;
  line-height: 1.5;
}

.offer-text strong {
  color: #00aeef;
  font-size: 22px;
  font-weight: 700;
}

form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
}

input {
  width: 100%;
  padding: 14px;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  font-size: 16px;
  transition: all 0.2s ease;
  color: #002349;
  background: #f8fafc;
}

input:focus {
  border-color: #00aeef;
  background: #ffffff;
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 174, 239, 0.1);
}

input::placeholder {
  color: #a0aec0;
}

.claim-button,
.select-device-button {
  background-color: #002349;
  color: white;
  border: none;
  padding: 16px 32px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 12px;
  transition: all 0.2s ease;
  width: 100%;
  margin-top: 8px;
}

.claim-button:hover,
.select-device-button:hover {
  background-color: #003366;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 35, 73, 0.2);
}

.no-thanks {
  font-size: 14px;
  color: #718096;
  cursor: pointer;
  margin-top: 16px;
  transition: color 0.2s ease;
  text-decoration: none;
}

.no-thanks:hover {
  color: #4a5568;
}

.disclaimer {
  font-size: 12px;
  color: #718096;
  font-style: italic;
  margin-top: 16px;
}

.error-message {
  color: #e53e3e;
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  padding: 8px 12px;
  background: #fff5f5;
  border-radius: 8px;
  border: 1px solid #feb2b2;
}

.success-message {
  color: #2f855a;
  font-size: 18px;
  font-weight: 600;
  margin: 24px 0;
  padding: 16px;
  background: #f0fff4;
  border-radius: 12px;
  border: 1px solid #9ae6b4;
}

@media (max-width: 480px) {
  .popup-content {
    padding: 30px 20px;
    margin: 20px;
  }

  h2 {
    font-size: 28px;
  }

  .thank-u-text {
    font-size: 16px;
  }

  .offer-text {
    font-size: 16px;
  }

  .offer-text strong {
    font-size: 20px;
  }

  input {
    padding: 12px;
    font-size: 15px;
  }

  .claim-button,
  .select-device-button {
    padding: 14px 28px;
    font-size: 15px;
  }
}
