.support-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  margin-top: 50px;
}

.faq-section h1 {
  text-align: center;
  margin-bottom: 30px;
}

.faq-item {
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  padding: 15px;
  position: relative;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  position: relative;
}

.faq-question::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  transition: transform 0.3s ease;
}

.faq-item.open .faq-question::after {
  transform: translateY(-50%) rotate(180deg);
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.faq-item.open .faq-answer {
  max-height: 200px;
  padding-top: 15px;
}

.faq-link {
  display: block; /* or inline-block, depending on preferred styling */
  margin-top: 10px;
  color: #007BFF; /* Blue color for the link */
  text-decoration: none;
}

.faq-link:hover {
  text-decoration: underline;
}

footer {
  margin-top: 30px;
}
