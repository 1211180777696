@import url("https://fonts.googleapis.com/css2?family=Alata&family=Jost:wght@100&family=Madimi+One&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

.device-selection-card-page {
  width: 40%;
  margin-bottom: 50px;
}

.device-selection-card,
.left-device-selection-card {
  background-color: #ffffff;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: "Nunito", sans-serif;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  height: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.device-selection-card:hover,
.left-device-selection-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.12);
}


.device-type {
  font-size: 28px;
  font-weight: 800;
  color: #002349;
  margin-bottom: 16px;
  text-align: center;
}

.device-price {
  font-size: 20px;
  margin-bottom: 24px;
  font-weight: 600;
  color: #4a5568;
  display: flex;
  align-items: center;
  gap: 12px;
}

.device-price .original-price {
  text-decoration: line-through;
  color: #dc3545;
  font-size: 0.9em;
  opacity: 0.8;
}

.color-selection {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-bottom: 24px;
}

.color-option {
  border: 2px solid transparent;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease;
  position: relative;
}

.color-option:hover {
  transform: scale(1.1);
}

.color-option.selected {
  border-color: #002349;
  transform: scale(1.1);
}

.color-option.selected::after {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border: 2px solid #002349;
  border-radius: 50%;
  opacity: 0.3;
}

.feature-list,
.left-feature-list {
  list-style: none;
  padding: 0;
  margin: 24px 0;
  text-align: center;
  width: 100%;
}

.feature-list li,
.left-feature-list li {
  color: #4a5568;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  padding-left: 24px;
}

.feature-list li::before,
.left-feature-list li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #00aeef;
  font-weight: bold;
}

.tab-image-scroll{

  height: 260px;
}
.preorder-button,
.left-preorder-button {
  background-color: #002349;
  color: #ffffff;
  border: none;
  border-radius: 12px;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  width: auto;
  min-width: 160px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.preorder-button:hover,
.left-preorder-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 35, 73, 0.2);
}

.device-image-scroll,
.tab-image-scroll {
  margin: 30px 0;
  text-align: center;
}

.device-image-scroll img,
.tab-image-scroll img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.device-image-scroll:hover img,
.tab-image-scroll:hover img {
  transform: translateY(-5px);
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .device-type {
    font-size: 24px;
  }

  .device-price {
    font-size: 18px;
  }

  .feature-list li,
  .left-feature-list li {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .device-selection-card-page {
    width: 100%;
    margin: 20px 0;
  }

  .device-selection-card,
  .left-device-selection-card {
    padding: 30px;
  }

  .device-type {
    font-size: 22px;
  }

  .device-price {
    font-size: 16px;
  }

  .preorder-button,
  .left-preorder-button {
    padding: 12px 24px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .device-selection-card,
  .left-device-selection-card {
    padding: 20px;
  }

  .device-type {
    font-size: 20px;
  }

  .device-price {
    font-size: 14px;
  }

  .feature-list li,
  .left-feature-list li {
    font-size: 14px;
  }
}
