.sliderContent-mobileView {
  color: #000;
  padding-left: 5px; /* Reduced padding */
  padding-right: 5px; /* Reduced padding */
  /* Add padding-top and padding-bottom if needed */
}

.sliderContent-mobileView button {
  background-color: rgb(0, 25, 84);
  color: #ffffff;
  width: 100px; /* You might want to make this flexible with percentages */
  height: auto; /* Make the height auto to fit content */
  padding: 5px 10px; /* Adjust padding to your preference */
  font-size: 10px;
  margin-top: 5px; /* Adjust the top margin to reduce space */
}
.kosherLevelText{
  padding: 15px;
}

.sliderContent-mobileView h2 {
  color: #000;
  font-size: 20px;
  margin-bottom: 5px; /* Reduce bottom margin to bring text closer to the paragraph */
}

.sliderContent-mobileView p {
  color: #000;
  font-size: 15px;
  margin-bottom: 5px; /* Reduce bottom margin to bring text closer to the button */
}



.slides {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scroll-snap-points-x: repeat(300px);
  scroll-snap-type: mandatory;
  padding: 20px;
  margin-left: 15px;
  
}
.slides::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.slides::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}
.slides::-webkit-scrollbar-track {
  background: transparent;
}




.slides > div {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 150px;
  height: 150px;
  margin-right: 10px;
  border-radius: 10px;
  background: #eee;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slides > div:target {
/*   transform: scale(0.8); */
}
.author-info {
  background: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 0.75rem;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
}
.author-info a {
  color: white;
}


.slider > a {
  display: inline-flex;
  width: 1.5rem;
  height: 1.5rem;
  background: white;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 0 0.5rem 0;
  position: relative;
}
.slider > a:active {
  top: 1px;
}
.slider > a:focus {
  background: #000;
}

