.aboutus-container {
  font-family: 'Arial', sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: #333;
  padding-top: 100px;
}

.aboutus-header {
  text-align: center;
  padding: 40px 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #eaeaea;
  border-radius: 25px;
}

.aboutus-logo {
  max-height: 40px;
  margin-bottom: 20px;
}

.aboutus-content p {
  text-align: justify;
  line-height: 1.6;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .aboutus-header {
    padding: 20px 10px;
  }

  .aboutus-logo {
    max-height: 50px;
  }
}

@media (max-width: 500px) {
  .aboutus-container {
    padding: 10px;
  }

  .aboutus-content p {
    font-size: 14px;
  }
}

@media (max-height: 500px) {
  .aboutus-header {
    padding: 10px;
  }

  .aboutus-logo {
    max-height: 40px;
  }
}
