/* Header.css */
.mobile-view-header {
    background-color: #f8f8f8;
    color: rgb(0, 27, 96);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: relative;
  }
  .mobile-view-header-logo {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .mobile-view-header-logo span {
    font-weight: lighter;
  }
  
  .mobile-view-header-icons {
    display: flex;
    align-items: center;
  }
  
  .mobile-view-icon {
    margin: 0 1rem;
    cursor: pointer;
  }

  .mobile-view-navigation {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .mobile-view-navigation-list {
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  
  .mobile-view-navigation-item {
    padding: 15px;
    font-size: 24px;
    text-align: center;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
  }
  
  .mobile-view-navigation-item:last-child {
    border-bottom: none;
  }

  .dropdown-menu {
    display: flex;
    flex-direction: column;
    margin: 0; /* Reset margin for the list */
    padding: 15px;
    
  }
  
  .dropdown-menu li {
    padding: 10px;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  border-top: 1px solid #ddd;
  list-style-type: none; /* This removes the bullet points */
  }
  
  .apps-dropdown {
    position: relative;
  }
  .dropdown-menu li a {
    text-decoration: none; /* This removes the underline from links */
    color: inherit; /* This ensures the link color is the same as the list item */
  }
  /* Chevron icons size */
  .fa-chevron-up, .fa-chevron-down {
    font-size: 0.8em;
    margin-left: 10px;
  }
  
  /* Mobile view adjustments */
  @media (max-width: 768px) {
    .mobile-view-header {
      padding: 0.5rem;
    }
  
    .mobile-view-header-logo {
      font-size: 1.2rem;
    }
  
    .mobile-view-icon {
      margin: 0 0.5rem;
    }
  }
  