/* TermsAndConditions.css */
.terms-conditions {
    width: 90%;
    margin: 40px auto; /* Increase margin for a better centering */
    padding: 20px;
    background: #fff;
    height: calc(100vh - 80px); /* Subtracting top and bottom margin from the viewport height */
    font-family: Arial, sans-serif; /* Use a standard font */
  }
  
  .terms-conditions h1 {
    font-size: 28px; /* Larger font size for the main title */
    color: #000; /* Darker color for better contrast */
    text-align: left; /* Aligning text to the left as in the screenshot */
    margin-bottom: 10px; /* Space after the title */
  }
  
  .terms-conditions h2 {
    font-size: 22px;
    color: #000;
    text-align: left;
    margin-bottom: 30px; /* More space after the date */
  }
  
  .terms-conditions p {
    font-size: 16px;
    color: #333;
    line-height: 1.8;
    margin-bottom: 20px;
    text-align: justify; /* Justify paragraphs for cleaner edges */
  }
 