@import url("https://fonts.googleapis.com/css2?family=Alata&family=Jost:wght@100&family=Madimi+One&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

.desktop-side {
  background-color: #ffffff;
}

.hero-section {
  position: relative;
  height: 90vh;
  min-height: 750px;
  max-height: 1000px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #002349 0%, #003366 100%);
  z-index: 0;
  animation: gradientShift 15s ease-in-out infinite;
}

@keyframes gradientShift {
  0%, 100% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
}

.background-pattern {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    radial-gradient(circle at 20% 30%, rgba(0, 174, 239, 0.15) 0%, transparent 50%),
    radial-gradient(circle at 80% 70%, rgba(0, 174, 239, 0.15) 0%, transparent 50%);
  opacity: 0.8;
  animation: patternFloat 20s ease-in-out infinite;
}

@keyframes patternFloat {
  0%, 100% { transform: translate(0, 0); }
  50% { transform: translate(-20px, 20px); }
}

.hero-content {
  position: relative;
  z-index: 1;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: 0 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
}

.hero-text {
  flex: 1;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  opacity: 0;
  animation: fadeInSlideUp 1s ease-out forwards;
}

@keyframes fadeInSlideUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 174, 239, 0.15);
  border: 1px solid rgba(0, 174, 239, 0.3);
  padding: 10px 20px;
  border-radius: 25px;
  color: #00aeef;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 32px;
  backdrop-filter: blur(8px);
  box-shadow: 0 4px 12px rgba(0, 174, 239, 0.2);
  transition: all 0.3s ease;
  align-self: flex-start;
}

.hero-badge:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 174, 239, 0.3);
}

.hero-text h1 {
  font-size: 60px;
  font-weight: 800;
  color: #ffffff;
  margin: 0 0 32px;
  line-height: 1.1;
  letter-spacing: -0.5px;
  text-align: left;
  width: 100%;
}

.hero-features {
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.hero-feature {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 18px;
  opacity: 0;
  transform: translateX(-20px);
  animation: fadeInSlideRight 0.5s ease-out forwards;
  width: 100%;
  text-align: left;
}

.hero-feature:last-child {
  margin-bottom: 0;
}

.hero-feature:nth-child(1) { animation-delay: 0.3s; }
.hero-feature:nth-child(2) { animation-delay: 0.5s; }
.hero-feature:nth-child(3) { animation-delay: 0.7s; }

@keyframes fadeInSlideRight {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.feature-icon {
  color: #00aeef;
  margin-right: 16px;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  flex-shrink: 0;
}

.hero-feature span:last-child {
  flex: 1;
}

.cta-button {
  background: #00aeef;
  color: #ffffff;
  border: none;
  border-radius: 14px;
  padding: 20px 40px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 12px rgba(0, 174, 239, 0.3);
  position: relative;
  overflow: hidden;
  align-self: flex-start;
  margin-top: 8px;
  text-align: center;
}

.cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 24px rgba(0, 174, 239, 0.4);
  background: #0095cc;
}

.cta-button:hover::before {
  left: 100%;
}

.hero-device {
  flex: 1;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: fadeInSlideLeft 1s ease-out 0.5s forwards;
}

@keyframes fadeInSlideLeft {
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.device-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.floating-device {
  max-width: 100%;
  height: auto;
  filter: drop-shadow(0 20px 40px rgba(0, 0, 0, 0.3));
  animation: float 6s ease-in-out infinite;
  position: relative;
  z-index: 2;
}

.device-highlight {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, rgba(0, 174, 239, 0.2) 0%, transparent 70%);
  animation: pulse 4s ease-in-out infinite;
}

@keyframes float {
  0%, 100% { transform: translateY(0) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(1deg); }
}

@keyframes pulse {
  0%, 100% { transform: scale(1) translate(0, 0); opacity: 0.5; }
  50% { transform: scale(1.1) translate(-10px, 10px); opacity: 0.8; }
}

.devices-section {
  padding: 80px 20px;
  background: #ffffff;
  position: relative;
  overflow: hidden;
}

.devices-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 150px;
  background: linear-gradient(to bottom, rgba(0, 35, 73, 0.05), transparent);
  transform: skewY(-3deg);
  transform-origin: top right;
}

.devices-content {
  max-width: 1600px;
  margin: 0 auto;
  text-align: center;
}

.devices-content h2 {
  font-size: 42px;
  font-weight: 800;
  color: #002349;
  margin-bottom: 16px;
  position: relative;
  display: inline-block;
}

.devices-content h2::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background: linear-gradient(90deg, #00aeef 0%, #66d4ff 100%);
  border-radius: 2px;
}

.devices-content p {
  font-size: 18px;
  color: #4a5568;
  max-width: 600px;
  margin: 0 auto 40px;
  line-height: 1.6;
}

.devices-row {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding: 20px 0;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  perspective: 1000px;
}

.devices-row::-webkit-scrollbar {
  display: none;
}

.devices-row > div {
  flex: 0 0 320px;
  min-width: 320px;
  transform: translateZ(0);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.devices-row > div:hover {
  transform: translateZ(50px);
}

.os-section {
  padding: 100px 40px;
  background: linear-gradient(135deg, #002349 0%, #003366 100%);
  position: relative;
  overflow: hidden;
  color: #ffffff;
}

.os-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    radial-gradient(circle at 20% 30%, rgba(0, 174, 239, 0.1) 0%, transparent 50%),
    radial-gradient(circle at 80% 70%, rgba(0, 174, 239, 0.1) 0%, transparent 50%);
  opacity: 0.8;
}

.os-content {
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  text-align: center;
}

.os-content h2 {
  font-size: 42px;
  font-weight: 800;
  margin-bottom: 20px;
  background: linear-gradient(90deg, #ffffff, #e0e0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.os-content > p {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.9);
  max-width: 600px;
  margin: 0 auto 60px;
  line-height: 1.6;
}

.os-features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin-top: 40px;
}

.os-feature {
  background: rgba(255, 255, 255, 0.05);
  padding: 40px;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.os-feature:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.08);
}

.os-feature h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
  color: #00aeef;
}

.os-feature p {
  font-size: 16px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
}

.features-detail-section {
  padding: 100px 40px;
  background: #f8f9fa;
  position: relative;
  overflow: hidden;
}

.features-detail-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(circle at 10% 20%, rgba(0, 174, 239, 0.03) 0%, transparent 50%),
    radial-gradient(circle at 90% 80%, rgba(0, 174, 239, 0.03) 0%, transparent 50%);
  pointer-events: none;
}

.features-content {
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
}

.feature-block {
  margin-bottom: 80px;
}

.feature-block:last-child {
  margin-bottom: 0;
}

.feature-block h2 {
  font-size: 36px;
  font-weight: 800;
  color: #002349;
  text-align: center;
  margin-bottom: 50px;
  position: relative;
}

.feature-block h2::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background: linear-gradient(90deg, #00aeef 0%, #66d4ff 100%);
  border-radius: 2px;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.feature-item {
  background: #ffffff;
  padding: 30px;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.feature-item h3 {
  font-size: 22px;
  font-weight: 700;
  color: #002349;
  margin-bottom: 16px;
  position: relative;
  padding-left: 32px;
}

.feature-item h3::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #00aeef;
  font-weight: bold;
}

.feature-item p {
  font-size: 16px;
  line-height: 1.6;
  color: #4a5568;
  margin: 0;
}

.app-store-section {
  padding: 100px 0 60px;
  background: #ffffff;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-store-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 150px;
  background: linear-gradient(to bottom, rgba(0, 35, 73, 0.05), transparent);
  transform: skewY(-3deg);
  transform-origin: top left;
}

.app-store-section h2 {
  font-size: 42px;
  font-weight: 800;
  color: #002349;
  margin-bottom: 40px;
  position: relative;
}

.app-store-section h2::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background: linear-gradient(90deg, #00aeef 0%, #66d4ff 100%);
  border-radius: 2px;
}

.app-store-button {
  margin-top: 40px;
  background: #00aeef;
  color: #ffffff;
  border: none;
  border-radius: 14px;
  padding: 18px 36px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 12px rgba(0, 174, 239, 0.3);
  position: relative;
  overflow: hidden;
}

.app-store-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.app-store-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 24px rgba(0, 174, 239, 0.4);
  background: #0095cc;
}

.app-store-button:hover::before {
  left: 100%;
}

@media (max-width: 1400px) {
  .devices-row {
    justify-content: flex-start;
    padding: 20px;
  }
}

@media (max-width: 1024px) {
  .os-features {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .hero-content {
    padding: 0 30px;
    flex-direction: column;
    text-align: center;
    gap: 60px;
  }

  .hero-text {
    align-items: center;
    text-align: center;
  }

  .hero-badge {
    align-self: center;
  }

  .hero-text h1 {
    text-align: center;
  }

  .hero-features {
    align-items: center;
  }

  .hero-feature {
    justify-content: center;
    text-align: center;
  }

  .cta-button {
    align-self: center;
  }

  .devices-section {
    padding: 60px 0;
  }

  .devices-content h2 {
    font-size: 36px;
  }

  .devices-content p {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .devices-row {
    padding: 10px;
    gap: 20px;
  }

  .devices-row > div {
    flex: 0 0 300px;
    min-width: 300px;
  }

  .os-section {
    padding: 60px 20px;
  }

  .os-content h2 {
    font-size: 36px;
  }

  .os-content > p {
    font-size: 16px;
    margin-bottom: 40px;
  }

  .os-features {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .os-feature {
    padding: 30px;
  }

  .features-detail-section {
    padding: 60px 20px;
  }

  .feature-block {
    margin-bottom: 60px;
  }

  .feature-block h2 {
    font-size: 32px;
  }

  .feature-grid {
    gap: 20px;
  }

  .feature-item {
    padding: 25px;
  }

  .app-store-section {
    padding: 60px 0 40px;
  }

  .app-store-section h2 {
    font-size: 36px;
  }

  .app-store-button {
    padding: 16px 32px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .hero-content {
    padding: 0 20px;
  }

  .hero-text h1 {
    font-size: 36px;
  }

  .hero-feature {
    font-size: 16px;
  }

  .cta-button {
    padding: 16px 32px;
    font-size: 16px;
  }

  .devices-content h2 {
    font-size: 32px;
  }

  .devices-content p {
    font-size: 15px;
  }

  .devices-row > div {
    flex: 0 0 280px;
    min-width: 280px;
  }

  .os-content h2 {
    font-size: 32px;
  }

  .os-feature h3 {
    font-size: 20px;
  }

  .os-feature p {
    font-size: 15px;
  }

  .feature-block h2 {
    font-size: 28px;
  }

  .feature-item h3 {
    font-size: 20px;
  }

  .feature-item p {
    font-size: 15px;
  }

  .app-store-button {
    padding: 14px 28px;
    font-size: 15px;
  }
}
