.why-choose-maxsip-container {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/maxsip-customer.appspot.com/o/Rectangle%201603%20(1).png?alt=media&token=9a743e5c-1482-4fbe-81f1-e1c7e7b6389a.png');
    background-size: cover;
    text-align: center;
  }

  .why-choose-maxsip-container h1{
    color: #000;
  }
  
  .feature-card {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    height: 200px;

  }
  
  .feature-card h2 {
    color: #001667;
    font-size: 20px;
  }
  
  .feature-card p {
    font-size: 15px;
    color: #333;
  }
  
  .feature-card img {
    margin-top: 10px;
    width: 60px; /* Adjust based on your icon */
  }
  