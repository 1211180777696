.image-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 20px 0;
  background: linear-gradient(135deg, #002349 0%, #003366 100%);
  min-height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stacked-div {
  font-size: 42px;
  font-weight: 800;
  color: #ffffff;
  text-align: center;
  position: relative;
  margin-bottom: 150px;
  margin-top: 40px;
}

.stacked-div::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background: linear-gradient(90deg, #00aeef 0%, #66d4ff 100%);
  border-radius: 2px;
}

.row {
  display: flex;
  gap: 20px;
  margin: 10px 0;
  animation: slide 60s linear infinite;
  width: max-content;
  position: relative;
  z-index: 1;
}

.top-row {
  animation-duration: 65s;
}

.bottom-row {
  animation-duration: 55s;
}

.slider-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.slider-image:hover {
  transform: translateY(-5px) scale(1.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.center-mobile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  height: 580px;
  z-index: 2;
  overflow: hidden;
  border-radius: 30px;
}

.center-mobile img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 3;
}

.mobile-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 4;
}

.app-bar {
  display: flex;
  justify-content: space-between;
  color: #b6afaf;
  font-size: 14px;
  padding: 20px 20px 0;
}

.content {
  position: absolute;
  top: 18px;
  left: 20px;
  right: 20px;
  bottom: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #000;
  border-radius: 15px;
  z-index: -1; /* Moves the layer to the back */
}

.album-art {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.album-art img {
  max-width: 95%;
  max-height: 95%;
  width: auto;
  height: auto;
  object-fit: contain;
  display: block;
  margin: auto;
}

.create-store-button {
  margin-top: auto;
  margin-bottom: 40px;
  background: #00aeef;
  color: #ffffff;
  border: none;
  border-radius: 14px;
  padding: 18px 36px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  z-index: 3;
  position: relative;
}

.create-store-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.create-store-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 24px rgba(0, 174, 239, 0.4);
  background: #0095cc;
}

.create-store-button:hover::before {
  left: 100%;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-33.33%);
  }
}

.fade-in {
  animation: fadeIn 4s ease-in forwards;
}

.fade-out {
  animation: fadeOut 3s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .stacked-div {
    font-size: 36px;
  }

  .create-store-button {
    padding: 16px 32px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .stacked-div {
    font-size: 32px;
  }

  .create-store-button {
    padding: 14px 28px;
    font-size: 15px;
  }
}
