/* VerifyPageForMobile.css */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 80%; /* Responsive width */
    max-width: 500px; /* Max width */
  }
  
  .modal h2 {
    margin-top: 0;
  }
  
  .modal p {
    margin-bottom: 10px;
  }
  

  